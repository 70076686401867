import axios from "axios";
import { getUserInfo, setUserInfo } from "@/lib/auth";
import store from "@/store";

let refreshingPromise: Promise<void> | null = null;

axios.interceptors.request.use(
  async (config) => {
    const { token, expired } = getUserInfo();

    if (token && expired && !refreshingPromise) {
      refreshingPromise = new Promise((resolve) => {
        axios
          .post("/profile/token/refresh", null, {
            headers: { "X-Auth-Token": token },
            isAxiosRetry: false,
          })
          .then(({ data: { token: newToken, expires } }) => {
            store.commit(
              "auth/setUserInfo",
              { token: newToken, expires },
              { root: true }
            );

            setUserInfo({ token: newToken, expires });

            resolve();
            refreshingPromise = null;
          })
          .catch(() => {
            store.dispatch("auth/logOut", { sendLogoutRequest: false });
            resolve();
            refreshingPromise = null;
          });
      });
    }

    if (!config.url?.includes("/profile/token/refresh")) {
      await refreshingPromise;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
