import { Action } from "vuex";
import axios from "axios";
import { RootState } from "@store/types";
import { WalletState } from "../types";

const transferFundsWallet: Action<WalletState, RootState> = async (
  _,
  { amount, currency, to, secondFactorCode, from }
) => {
  const { data } = await axios.post("/wallet/transferFunds", {
    from,
    amount,
    currency,
    to,
    secondFactorCode,
  });

  return data;
};

export default transferFundsWallet;
