import "driver.js/dist/driver.css";
import "./index.scss";

import Vue, { PluginObject } from "vue";
import app from "@/main";
import { ProductTour, ProductTourModel, ProductTourStep } from "./types";

const model = new Vue<ProductTourModel>({
  data() {
    return {
      activeIntro: {
        type: null,
        step: {
          index: 0,
          alias: "",
        },
      },
    };
  },
});

const ProductTourPlugin: PluginObject<undefined> = {
  async install() {
    const $productTour: ProductTour = {
      activeIntro: model.activeIntro,
      async intro({
        prevBtnText = app.$vuetify.lang.t(
          "$vuetify.product_tour.prev_btn_text"
        ),
        nextBtnText = app.$vuetify.lang.t(
          "$vuetify.product_tour.next_btn_text"
        ),
        doneBtnText = app.$vuetify.lang.t(
          "$vuetify.product_tour.done_btn_text"
        ),
        progressText = app.$vuetify.lang.t(
          "$vuetify.product_tour.progress_text"
        ),
        introType,
        onHighlightStarted,
        onDestroyed,
        onPopoverRender,
        ...config
      }) {
        const { driver } = await import("driver.js");

        const driverObj = driver({
          ...config,
          prevBtnText,
          nextBtnText,
          doneBtnText,
          progressText,
          onHighlightStarted(...args) {
            toggleIntroModel(true);
            onHighlightStarted && onHighlightStarted(...args);
          },
          onDestroyed(...args) {
            toggleIntroModel(false);
            onDestroyed && onDestroyed(...args);
          },
          onPopoverRender(popover, opts) {
            const activeStep = opts.state.activeStep as ProductTourStep;
            const customButtons = activeStep?.popover?.customButtons?.filter(
              ({ enabled = true }) => enabled
            );

            popover.footerButtons.classList.toggle(
              "driver-popover-navigation-btns_with-custom-btns",
              !!customButtons?.length
            );

            customButtons?.forEach(({ text, onClick }) => {
              const customButton = document.createElement("button");

              customButton.innerText = text;
              customButton.addEventListener("click", onClick);

              popover.footerButtons.appendChild(customButton);
            });

            onPopoverRender && onPopoverRender(popover, opts);
          },
        });

        const toggleIntroModel = (showed: boolean, stepIndex?: number) => {
          model.activeIntro.type = showed ? introType : null;
          document.body.classList.toggle(`driver-${introType}-active`, showed);

          if (showed) {
            const steps = config.steps || [];

            stepIndex = Math.min(
              stepIndex ?? driverObj.getActiveIndex() ?? 0,
              steps.length - 1
            );

            const step = steps[stepIndex];
            const stepAlias = step.alias || "";

            model.activeIntro.step.index = stepIndex;
            model.activeIntro.step.alias = stepAlias;
          } else {
            model.activeIntro.step.alias = "";
            model.activeIntro.step.index = 0;
          }
        };

        toggleIntroModel(true);

        return {
          ...driverObj,
          toggleLoading(loading) {
            document.body.classList.toggle("driver-step-loading", loading);
          },
          toggleIntroModel,
        };
      },
      dispatchEvent(event, ...args) {
        model.$emit(event, ...args);
      },
      addEventListener(event, callback) {
        model.$on(event, callback);
      },
      removeEventListener(event, callback) {
        model.$off(event, callback);
      },
    };

    Vue.prototype.$productTour = $productTour;
  },
};

export default ProductTourPlugin;
