import { RootState } from "@store/types";
import { Getter } from "vuex";
import { WalletState, WalletGetters } from "../types";

const walletsList: Getter<WalletState, RootState> = (state) => {
  const wallets: WalletGetters["walletsList"] = state.wallets.list;

  return wallets;
};

export default walletsList;
