import { MutationTree } from "vuex";
import { NewsState } from "../types";

import setFeedNewsList from "./setFeedNewsList";
import setNewsPopup from "./setNewsPopup";
import setFeedNewsLoading from "./setFeedNewsLoading";
import setNewsPopupLoading from "./setNewsPopupLoading";

const mutations: MutationTree<NewsState> = {
  setFeedNewsLoading,
  setNewsPopupLoading,
  setFeedNewsList,
  setNewsPopup,
};

export default mutations;
