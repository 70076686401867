import events from "./events";
import Vue, { PluginObject } from "vue";
import { NotificationOptions } from "./types";

import Notifications from "./components/Notifications/Notifications.vue";

const NotificationsPlugin: PluginObject<undefined> = {
  install() {
    Vue.prototype.$notify = (params: NotificationOptions) => {
      events.$emit("add", params);
    };

    Vue.component("Notifications", Notifications);
  },
};

export default NotificationsPlugin;
