import { Getter } from "vuex";
import { RootState } from "@store/types";
import { TeamState, TeamGetters } from "../types";

const enabledMembers: Getter<TeamState, RootState> = (
  _,
  getters: TeamGetters
) => {
  return getters.members.filter(({ enabled }) => enabled);
};

export default enabledMembers;
