import { ActionTree } from "vuex";
import { PartnerState } from "../types";
import { RootState } from "@store/types";
import fetchPartners from "./fetchPartners";

const actions: ActionTree<PartnerState, RootState> = {
  fetchPartners,
};

export default actions;
