import { Action } from "vuex";
import API from "@api";
import { RootState } from "@store/types";
import { TeamState } from "../types";

const refreshTeamInvite: Action<TeamState, RootState> = () => {
  return API.team.refreshTeamInvite();
};

export default refreshTeamInvite;
