import { Getter } from "vuex";
import { RootState } from "@store/types";
import { FaqState, FaqGetters } from "../types";

const faqLoading: Getter<FaqState, RootState> = (state) => {
  const getter: FaqGetters["faqLoading"] = (langCode) => {
    return state.loading[langCode];
  };

  return getter;
};

export default faqLoading;
