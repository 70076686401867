import Vue from "vue";
import PortalVue from "portal-vue";
import LottieVuePlayer from "@lottiefiles/vue-lottie-player";

import vuetify from "@/plugins/vuetify";
import initSentry from "@/plugins/sentry";
import "@/plugins/class-component-hooks";
import "@/plugins/axios-retry";
import "@/plugins/axios";
import GoogleRecaptchaPlugin from "@/plugins/google-recaptcha";
import GlobalComponentsPlugin from "@/plugins/global-components";
import NotificationsPlugin from "@/plugins/notifications";
import ProductTourPlugin from "@/plugins/product-tour";
import AutoAnimatePlugin from "@/plugins/auto-animate";

import router from "./router";
import store from "./store";
import "./registerServiceWorker";
import { getUserProfileInfo } from "./lib/auth";

import App from "./App/App.vue";

Vue.use(PortalVue);
Vue.use(GlobalComponentsPlugin);
Vue.use(NotificationsPlugin);
Vue.use(ProductTourPlugin);
Vue.use(AutoAnimatePlugin);
Vue.use(LottieVuePlayer);
Vue.use(GoogleRecaptchaPlugin, {
  siteKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY,
});

Vue.config.productionTip = false;

const app = new Vue({
  vuetify,
  router,
  store,
  render(h) {
    return h("div", [h(App)]);
  },
  async created() {
    const Sentry = await initSentry({ router: this.$router });

    if (Sentry) {
      this.$watch(
        () => {
          return [
            this.$store.getters["profile/profileEmail"],
            this.$store.getters["profile/profileId"],
            this.$store.getters["profile/profileFullName"],
            this.$store.getters["auth/token"],
          ].join("-");
        },
        (data) => {
          const [email, id, userName, token] = data.split("-");

          const profileFromLocalStorage = getUserProfileInfo();

          Sentry.configureScope((scope) => {
            scope.setUser({
              id: id || profileFromLocalStorage.id,
              email: email || profileFromLocalStorage.email,
              userName,
              token,
            });
          });
        },
        {
          immediate: true,
        }
      );
    }
  },
}).$mount("#app");

export default app;
