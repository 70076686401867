import { Action } from "vuex";
import API from "@api";
import { RootState } from "@store/types";
import { WarningState } from "../types";

const fetchFeedWarning: Action<WarningState, RootState> = async ({
  commit,
  state,
}) => {
  if (state.feed.loading) return;

  commit("setFeedWarningLoading", true);

  try {
    const warningList = await API.warning.fetchFeedWarning();

    commit("setFeedWarningList", warningList);
    commit("setFeedWarningLoading", false);
  } catch {
    commit("setFeedWarningLoading", false);
  }
};

export default fetchFeedWarning;
