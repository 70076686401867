import axios from "axios";
import { Action } from "vuex";
import { RootState } from "@store/types";
import { CardState } from "../types";

const closeCard: Action<CardState, RootState> = async (_, id) => {
  const { data } = await axios.post(`/card/${id}/close`);

  return data;
};

export default closeCard;
