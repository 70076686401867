import { RootState } from "@store/types";
import { GetterTree } from "vuex";
import { BankState } from "../types";
import allBanks from "./allBanks";
import allRealBanks from "./allRealBanks";
import userBanks from "./userBanks";
import teamBanks from "./teamBanks";
import loadingAllBanks from "./loadingAllBanks";
import loadingUserBanks from "./loadingUserBanks";
import loadingTeamBanks from "./loadingTeamBanks";
import banksWithPrepaidCards from "./banksWithPrepaidCards";
import banksWithPostpaidCards from "./banksWithPostpaidCards";

const getters: GetterTree<BankState, RootState> = {
  allBanks,
  allRealBanks,
  userBanks,
  teamBanks,
  loadingAllBanks,
  loadingUserBanks,
  loadingTeamBanks,
  banksWithPrepaidCards,
  banksWithPostpaidCards,
};

export default getters;
