import { RootState } from "@store/types";
import { GetterTree } from "vuex";
import { MessageState } from "../types";
import countNotReadMessages from "./countNotReadMessages";
import feedMessages from "./feedMessages";
import loadingFeedNews from "./loadingFeedNews";
import notReadMessages from "./notReadMessages";

const getters: GetterTree<MessageState, RootState> = {
  feedMessages,
  countNotReadMessages,
  loadingFeedNews,
  notReadMessages,
};

export default getters;
