import axios from "axios";
import { Action } from "vuex";
import { RootState } from "@store/types";
import { AuthState } from "../types";

const restorePassword: Action<AuthState, RootState> = async (_, { email }) => {
  const { data: checked } = await axios.post("/auth/password/restore", {
    email,
  });

  console.log("checked", checked);

  return checked;
};

export default restorePassword;
