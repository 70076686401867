import { RootState } from "@store/types";
import { Getter } from "vuex";
import { BankState } from "../types";

const allRealBanks: Getter<BankState, RootState> = (state) => {
  const TEST_BANK_ID = 1;

  const excludeBankIds = [TEST_BANK_ID];

  return state.allBanks.list.filter(
    (bank) => !excludeBankIds.includes(bank.id)
  );
};

export default allRealBanks;
