import { NewsState } from "./types";

export default (): NewsState => ({
  feed: {
    loading: false,
    list: [],
  },
  popup: {
    current: null,
    loading: false,
  },
});
