import { Getter } from "vuex";
import { RootState } from "@store/types";
import { TeamState, TeamGetters } from "../types";

const totalTeamBalance: Getter<TeamState, RootState> = (
  state,
  getters: TeamGetters
) => {
  return getters.members.reduce((sum, { totalUsd }) => sum + totalUsd, 0);
};

export default totalTeamBalance;
