import { ActionTree } from "vuex";
import { AuthState } from "../types";
import { RootState } from "@store/types";
import logIn from "./logIn";
import logOut from "./logOut";
import signUp from "./signUp";
import restorePassword from "./restorePassword";
import checkSecretCode from "./checkSecretCode";
import changePassword from "./changePassword";

const actions: ActionTree<AuthState, RootState> = {
  logIn,
  logOut,
  signUp,
  restorePassword,
  checkSecretCode,
  changePassword,
};

export default actions;
