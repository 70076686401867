import { RootState } from "@store/types";
import { Getter } from "vuex";
import { MessageState, MessageGetters } from "../types";

const countNotReadMessages: Getter<MessageState, RootState> = (
  _,
  getters: MessageGetters
) => {
  return getters.notReadMessages.length;
};

export default countNotReadMessages;
