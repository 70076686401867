import API from "@api";
import { Action } from "vuex";
import { RootState } from "@store/types";
import { MessageState } from "../types";
import { MessageType } from "@/types/message";

const fetchFeedMessages: Action<MessageState, RootState> = async ({
  commit,
  state,
}) => {
  if (state.feed.loading) return;

  commit("setFeedMessagesLoading", true);

  try {
    const messages = await API.feed.searchFeedMessages({
      types: [MessageType.FACEBOOK_CODE_EVENT],
      searchAfter: new Date(
        new Date().setMonth(new Date().getMonth() - 1)
      ).toISOString(),
    });

    commit("setFeedMessagesList", messages.slice(-10));
    commit("setFeedMessagesLoading", false);
  } catch {
    commit("setFeedMessagesLoading", false);
  }
};

export default fetchFeedMessages;
