import { Mutation } from "vuex";
import { AuthState } from "../types";
import { removeUserInfo, setUserInfo as aSetUserInfo } from "@/lib/auth";

const setUserInfo: Mutation<AuthState> = (state, { token, expires }) => {
  if (token) {
    aSetUserInfo({
      token,
      expires,
    });
  } else {
    removeUserInfo();
  }

  state.token = token;
};

export default setUserInfo;
