import { RootState } from "@store/types";
import { Role } from "@/types/role";
import { Getter } from "vuex";
import { ProfileState } from "../types";

const profileAuthority: Getter<ProfileState, RootState> = (state) => {
  return state.profile?.authority || Role.ROLE_MEDIABUYER;
};

export default profileAuthority;
