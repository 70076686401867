import { MutationTree } from "vuex";
import { CardState } from "../types";

import setCardGroups from "./setCardGroups";
import setCards from "./setCards";
import setCardsLoaded from "./setCardsLoaded";
import setCardsLoading from "./setCardsLoading";

const mutations: MutationTree<CardState> = {
  setCardGroups,
  setCards,
  setCardsLoaded,
  setCardsLoading,
};

export default mutations;
