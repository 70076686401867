import { Action } from "vuex";
import axios from "axios";
import { RootState } from "@store/types";
import { BillingState } from "../types";

const createBillingInvoice: Action<BillingState, RootState> = async (
  _,
  payload
) => {
  const { data } = await axios.post("/billing/invoice/create", payload);

  return data;
};

export default createBillingInvoice;
