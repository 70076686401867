import { Action } from "vuex";
import axios from "axios";
import { RootState } from "@store/types";
import { NewsState } from "../types";
import { News } from "@/types/news";

const fetchFeedNews: Action<NewsState, RootState> = async ({
  commit,
  state,
}) => {
  if (state.feed.loading) return;

  commit("setFeedNewsLoading", true);

  try {
    const {
      data: { items },
    } = await axios.get<{ items: News[] }>("/news/feed");

    commit("setFeedNewsList", items);
    commit("setFeedNewsLoading", false);
  } catch {
    commit("setFeedNewsLoading", false);
  }
};

export default fetchFeedNews;
