import { ActionTree } from "vuex";
import { StatisticState } from "../types";
import { RootState } from "@store/types";
import fetchStatisticDeclines from "./fetchStatisticDeclines";

const actions: ActionTree<StatisticState, RootState> = {
  fetchStatisticDeclines,
};

export default actions;
