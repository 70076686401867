var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"mobile-screen py-4"},[(_vm.title)?_c('header',{class:[
      'mobile-screen__header d-flex align-center text-h6 font-weight-bold mb-4',
      {
        'blue-grey--text text--darken-4': !_vm.lightHeader,
        'white--text': _vm.lightHeader,
      },
    ]},[(_vm.icon)?_c('v-icon',{staticClass:"mr-2 flex-shrink-0",attrs:{"size":"24","color":"blue-grey darken-4"}},[_vm._v(" "+_vm._s(_vm.icon)+" ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.title)+" "),_vm._t("title-append")],2):_vm._e(),_c('div',{staticClass:"mobile-screen__inner"},[_vm._t("default"),(_vm.$scopedSlots.content)?_c('div',{staticClass:"mobile-screen__content"},[_vm._t("content")],2):_vm._e()],2),_c('v-fade-transition',[(_vm.loading)?_c('div',{staticClass:"mobile-screen__loader-wrap text-center mx-auto pa-2 elevation-5"},[_c('v-progress-circular',{staticClass:"mobile-screen__loader",attrs:{"indeterminate":"","size":"25","width":"2","color":"primary"}})],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }