import { Module } from "vuex";
import { RootState } from "@store/types";
import { BlockchainState } from "./types";
import state from "./state";
import actions from "./actions";

const module: Module<BlockchainState, RootState> = {
  namespaced: true,
  state,
  actions,
};

export default module;
