import axios from "axios";
import { Action } from "vuex";
import { RootState } from "@store/types";
import { FaqSection } from "@/types/faq";
import { FaqState, FaqGetters } from "../types";
import { LangCode } from "@/types/lang";

const fetchFaq: Action<FaqState, RootState> = async (
  { commit, getters },
  { parentID, langCode }: { parentID: number; langCode: LangCode }
) => {
  const faqLoadingGetter = getters.faqLoading as FaqGetters["faqLoading"];
  const faqListGetter = getters.faqList as FaqGetters["faqList"];

  const faqLoading = faqLoadingGetter(langCode);
  const faqList = faqListGetter(langCode);

  if (faqLoading || faqList.length > 0) return;

  commit("setFaqLoading", { langCode, value: true });

  try {
    const { data } = await axios.get<FaqSection[]>(
      `/faq/${parentID}/${langCode}`
    );

    data.sort((a, b) => a.order - b.order);

    data.forEach((item) => {
      item.children.sort((a, b) => a.order - b.order);
    });

    commit("setFaqLoading", { langCode, value: false });
    commit("setFaqList", { langCode, value: data });
  } catch {
    commit("setFaqLoading", { langCode, value: false });
  }
};

export default fetchFaq;
