import { Action } from "vuex";
import { RootState } from "@store/types";
import { ProfileState } from "../types";
import axios from "axios";

const enableSecondFactor: Action<ProfileState, RootState> = async (
  _,
  { code, secret }
) => {
  return axios.post("/profile/secondFactor/enable", {
    code,
    secret,
  });
};

export default enableSecondFactor;
