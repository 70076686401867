import axios from "axios";
import { Action } from "vuex";
import { RootState } from "@store/types";
import { CardState } from "../types";

const updateCard: Action<CardState, RootState> = async (
  _,
  { id, ...params }
) => {
  const { data } = await axios.post(`/card/${id}/update`, params);

  return data;
};

export default updateCard;
