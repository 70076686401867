import { Action } from "vuex";
import { RootState } from "@store/types";
import { ProfileState } from "../types";
import axios from "axios";

const generateSecondFactor: Action<ProfileState, RootState> = async () => {
  const { data } = await axios.post("/profile/secondFactor/generate");

  return data;
};

export default generateSecondFactor;
