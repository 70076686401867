import { Mutation } from "vuex";
import { ProfileState } from "../types";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import defaultAvatar from "@/assets/img/avatar.png";
import { Profile } from "@/types/profile";

const setProfile: Mutation<ProfileState> = (state, payload: Profile | null) => {
  if (payload) {
    state.status = "active";
    state.profile = {
      ...payload,
      avatar: payload.avatar || defaultAvatar,
    };
  } else {
    state.status = null;
    state.profile = null;
  }
};

export default setProfile;
