import { RootState } from "@store/types";
import { Getter } from "vuex";
import { ProfileState, ProfileGetters } from "../types";
import { getUserLang, checkValidLangCode } from "@/lib/userLang";
import { LangCode } from "@/types/lang";

const profileLanguage: Getter<ProfileState, RootState> = (state) => {
  const getter: ProfileGetters["profileLanguage"] = (fallbackLangCode) => {
    const profileLanguageCode = state.profile?.language;

    if (profileLanguageCode && checkValidLangCode(profileLanguageCode)) {
      return profileLanguageCode as LangCode;
    }

    if (fallbackLangCode && checkValidLangCode(fallbackLangCode)) {
      return fallbackLangCode as LangCode;
    }

    return getUserLang();
  };

  return getter;
};

export default profileLanguage;
