import { Mutation } from "vuex";
import { ProfileState } from "../types";

const setProfileLanguage: Mutation<ProfileState> = (state, payload: string) => {
  if (!state.profile) return;

  state.profile.language = payload;
};

export default setProfileLanguage;
