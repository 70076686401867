import { ActionTree } from "vuex";
import { ProfileState } from "../types";
import { RootState } from "@store/types";
import fetchProfile from "./fetchProfile";
import fetchAvailablePromocodes from "./fetchAvailablePromocodes";
import updateProfile from "./updateProfile";
import updateProfileAvatar from "./updateProfileAvatar";
import profileRefreshToken from "./profileRefreshToken";
import disableSecondFactor from "./disableSecondFactor";
import enableSecondFactor from "./enableSecondFactor";
import generateSecondFactor from "./generateSecondFactor";
import updateProfilePassword from "./updateProfilePassword";

const actions: ActionTree<ProfileState, RootState> = {
  fetchProfile,
  fetchAvailablePromocodes,
  updateProfile,
  updateProfileAvatar,
  profileRefreshToken,
  disableSecondFactor,
  enableSecondFactor,
  generateSecondFactor,
  updateProfilePassword,
};

export default actions;
