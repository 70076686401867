import { Module } from "vuex";
import { RootState } from "@store/types";
import { CardState } from "./types";

import state from "./state";
import getters from "./getters";
import actions from "./actions";
import modules from "./modules";
import mutations from "./mutations";

const module: Module<CardState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  modules,
  mutations,
};

export default module;
