import { Getter } from "vuex";
import { RootState } from "@store/types";
import { TeamMember } from "@/types/team";
import { uniqCid } from "@helpers";
import { TeamState } from "../types";

const flatMembers = (topMember?: TeamMember | null): TeamMember[] => {
  const list: TeamMember[] = [];

  const parentMember: TeamMember | undefined = topMember
    ? Object.freeze({
        ...topMember,
        cid: uniqCid().toString(),
      })
    : undefined;

  if (parentMember?.parentMember) {
    list.push(parentMember);
  }

  topMember?.members?.forEach((childMember) => {
    list.push(
      ...flatMembers({
        ...childMember,
        parentMember,
        cid: uniqCid().toString(),
      })
    );
  });

  return list;
};

const members: Getter<TeamState, RootState> = (state) => {
  return flatMembers(state.teamHierarchy.value);
};

export default members;
