import { MutationTree } from "vuex";
import { BankState } from "../types";
import setUserBanks from "./setUserBanks";
import setUserBanksLoading from "./setUserBanksLoading";
import setAllBanks from "./setAllBanks";
import setAllBanksLoading from "./setAllBanksLoading";
import setTeamBanks from "./setTeamBanks";
import setTeamBanksLoading from "./setTeamBanksLoading";

const mutations: MutationTree<BankState> = {
  setUserBanks,
  setAllBanks,
  setTeamBanks,
  setAllBanksLoading,
  setUserBanksLoading,
  setTeamBanksLoading,
};

export default mutations;
