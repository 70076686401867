import { Currency } from "./currency";

export enum PromocodeType {
  DEPOSIT_BONUS = "DEPOSIT_BONUS",
  FREE_CARDS_BONUS = "FREE_CARDS_BONUS",
}

export enum PromocodeStatus {
  COMPLETED = "COMPLETED",
  ACTIVE = "ACTIVE",
}

export interface Promocode {
  date: string;
  type: PromocodeType;
  code: string;
  bonus: number;
  bonusUsed: number;
  currency: Currency;
  status: PromocodeStatus;
}
