import { Action } from "vuex";
import axios from "axios";
import { RootState } from "@store/types";
import { BankState } from "../types";
import { DictionaryBank } from "@/types/dictionary";

const fetchAllBanks: Action<BankState, RootState> = async ({
  commit,
  state,
}) => {
  if (state.allBanks.loading || state.allBanks.list.length > 0) return;

  commit("setAllBanksLoading", true);

  try {
    const { data } = await axios.get<DictionaryBank[]>("/bank/dictionary");

    commit("setAllBanks", data);
    commit("setAllBanksLoading", false);
  } catch {
    commit("setAllBanksLoading", false);
  }
};

export default fetchAllBanks;
