import { RootState } from "@store/types";
import { Getter } from "vuex";
import { CardState } from "../types";
import { Card } from "@/types/card";

const cardsByType: Getter<CardState, RootState> = (state) => {
  return state.cards.list.reduce<{
    prepaidCards: Card[];
    postpaidCards: Card[];
  }>(
    (acc, item) => {
      if (item.prepaid) {
        acc.prepaidCards.push(item);
      } else {
        acc.postpaidCards.push(item);
      }

      return acc;
    },
    {
      prepaidCards: [],
      postpaidCards: [],
    }
  );
};

export default cardsByType;
