import { Action } from "vuex";
import API from "@api";
import { RootState } from "@store/types";
import { DashboardSummary } from "@/types/dashboard";

let fetchDashboardSummaryPromise: Promise<DashboardSummary> | null = null;

const fetchDashboardSummary: Action<RootState, RootState> = async ({
  commit,
}) => {
  if (fetchDashboardSummaryPromise) {
    return fetchDashboardSummaryPromise;
  }

  commit("news/setFeedNewsLoading", true, { root: true });
  commit("news/setNewsPopupLoading", true, { root: true });
  commit("wallet/setWalletsLoading", true, { root: true });
  commit("warning/setFeedWarningLoading", true, { root: true });
  commit("statistic/setStatisticDeclinesLoading", true, { root: true });

  fetchDashboardSummaryPromise = API.dashboard.fetchDashboardSummary();

  try {
    const { newsFeed, newsPopup, walletList, warningFeed, statisticDeclines } =
      await fetchDashboardSummaryPromise;

    commit("news/setNewsPopup", newsPopup || null);
    commit("news/setFeedNewsList", newsFeed.items, { root: true });
    commit("wallet/setWallets", walletList, { root: true });
    commit("wallet/setWalletsLoaded", true, { root: true });
    commit("warning/setFeedWarningList", warningFeed, { root: true });
    commit("statistic/setStatisticDeclines", statisticDeclines, { root: true });
  } finally {
    commit("news/setNewsPopupLoading", false, { root: true });
    commit("news/setFeedNewsLoading", false, { root: true });
    commit("wallet/setWalletsLoading", false, { root: true });
    commit("warning/setFeedWarningLoading", false, { root: true });
    commit("statistic/setStatisticDeclinesLoading", false, { root: true });

    fetchDashboardSummaryPromise = null;
  }
};

export default fetchDashboardSummary;
