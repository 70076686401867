/* eslint-disable no-useless-escape */
export function getCookie(name: string): string | undefined {
  const matches = document.cookie.match(
    new RegExp(
      "(?:^|; )" +
        name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") +
        "=([^;]*)"
    )
  );

  return matches ? decodeURIComponent(matches[1]) : undefined;
}

interface Options {
  path: string;
  secure: boolean;
  expires?: string;
  "max-age"?: number;
}

export function setCookie(
  name: string,
  value: string | number,
  {
    path = "/",
    secure = false,
    expires,
    maxAge,
  }: {
    path?: string;
    expires?: Date;
    maxAge?: number;
    secure?: boolean;
  } = {}
): void {
  const options: Options = {
    path,
    secure,
    "max-age": maxAge,
  };

  if (expires instanceof Date) {
    options.expires = expires.toUTCString();
  }

  document.cookie = (Object.keys(options) as (keyof Options)[]).reduce(
    (acc, key) => {
      if (options[key]) {
        acc += `; ${key}=${options[key]}`;
      }

      return acc;
    },
    `${encodeURIComponent(name)}=${encodeURIComponent(value)}`
  );
}

export function deleteCookie(name: string): void {
  setCookie(name, "", {
    maxAge: -1,
  });
}
