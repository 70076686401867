import { ActionTree } from "vuex";
import { CardGroupState } from "../types";
import { RootState } from "@store/types";
import fetchCardGroups from "./fetchCardGroups";
import createCardGroup from "./createCardGroup";

const actions: ActionTree<CardGroupState, RootState> = {
  fetchCardGroups,
  createCardGroup,
};

export default actions;
