import { ActionTree } from "vuex";
import { CardState } from "../types";
import { RootState } from "@store/types";
import fetchCards from "./fetchCards";
import fetchCardCvv from "./fetchCardCvv";
import fetchCardNumber from "./fetchCardNumber";
import fetchBatchIssueCardList from "./fetchBatchIssueCardList";
import updateCard from "./updateCard";
import closeCard from "./closeCard";
import freezeCard from "./freezeCard";
import unfreezeCard from "./unfreezeCard";

const actions: ActionTree<CardState, RootState> = {
  fetchCards,
  fetchCardCvv,
  fetchCardNumber,
  fetchBatchIssueCardList,
  updateCard,
  closeCard,
  freezeCard,
  unfreezeCard,
};

export default actions;
