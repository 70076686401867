import { RootState } from "@store/types";
import { GetterTree } from "vuex";
import { NewsState } from "../types";
import feedNews from "./feedNews";
import feedNewsCount from "./feedNewsCount";
import loadingFeedNews from "./loadingFeedNews";
import popupNews from "./popupNews";
import loadingPopupNews from "./loadingPopupNews";
import lastNotReadNews from "./lastNotReadNews";
import countNotReadNews from "./countNotReadNews";

const getters: GetterTree<NewsState, RootState> = {
  feedNews,
  feedNewsCount,
  loadingFeedNews,
  popupNews,
  loadingPopupNews,
  countNotReadNews,
  lastNotReadNews,
};

export default getters;
