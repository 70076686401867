import { Mutation } from "vuex";
import { PartnerState } from "../types";
import { LangCode } from "@/types/lang";

const setPartnersLoading: Mutation<PartnerState> = (
  state,
  { langCode, value }: { langCode: LangCode; value: boolean }
) => {
  state.loading[langCode] = value;
};

export default setPartnersLoading;
