import axios from "axios";
import { Action } from "vuex";
import { RootState } from "@store/types";
import { UserState, UserGetters } from "../types";
import { UserWebPreferencesKey, UserWebPreferencesValue } from "@/types/user";

const updateUserWebPreferences: Action<UserState, RootState> = async (
  { commit, getters },
  { key, value }: { key: UserWebPreferencesKey; value: UserWebPreferencesValue }
) => {
  const { userIsGhost } = getters as UserGetters;

  commit("setUserWebPreferences", { key, value, loading: true });

  try {
    if (!userIsGhost) {
      await axios.post("/user/webpreferences/update", value, {
        params: {
          param: key,
        },
      });
    }

    commit("setUserWebPreferences", { key, loading: false });
  } catch {
    commit("setUserWebPreferences", { key, loading: false });
  }
};

export default updateUserWebPreferences;
