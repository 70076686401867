import { Action } from "vuex";
import axios from "axios";
import { RootState } from "@store/types";
import { NewsState } from "../types";
import { News } from "@/types/news";

const fetchPopupNews: Action<NewsState, RootState> = async ({
  commit,
  state,
}) => {
  if (state.popup.loading) return;

  commit("setNewsPopupLoading", true);

  try {
    const { data } = await axios.get<News | null>("/news/popup");

    commit("setNewsPopup", data || null);
    commit("setNewsPopupLoading", false);
  } catch {
    commit("setNewsPopupLoading", false);
  }
};

export default fetchPopupNews;
