import { RootState } from "@store/types";
import { Getter } from "vuex";
import { ProfileState } from "../types";
import { UserGetters } from "@store/modules/user/types";

const canViewTeamCardData: Getter<ProfileState, RootState> = (
  state,
  _getters,
  _rootState,
  rootGetters
) => {
  const userIsGhost = rootGetters[
    "user/userIsGhost"
  ] as UserGetters["userIsGhost"];

  if (userIsGhost) {
    return false;
  }

  return state.profile?.canViewTeamCardData ?? false;
};

export default canViewTeamCardData;
