import { Mutation } from "vuex";
import { PartnerState } from "../types";
import { LangCode } from "@/types/lang";
import { nestedFreeze } from "@helpers";
import { Partner } from "@/types/partner";

const setPartnersList: Mutation<PartnerState> = (
  state,
  { langCode, value }: { langCode: LangCode; value: Partner[] }
) => {
  state.list[langCode] = nestedFreeze(value);
};

export default setPartnersList;
