import { RootState } from "@store/types";
import { Getter } from "vuex";
import { WalletState, WalletGetters } from "../types";
import { Currency } from "@/types/currency";

const defaultWalletCurrency: Getter<WalletState, RootState> = (
  _,
  getters: WalletGetters
) => {
  return !getters.walletsCurrencies.length ||
    getters.walletsCurrencies.includes(Currency.USD)
    ? Currency.USD
    : getters.walletsCurrencies[0];
};

export default defaultWalletCurrency;
