import { RootState } from "@store/types";
import { Getter } from "vuex";
import { ProfileState } from "../types";

const cardIssueRemainingLimit: Getter<ProfileState, RootState> = (state) => {
  const cardIssueRemainingLimit = state.profile?.cardIssueRemainingLimit || 0;

  return Math.max(cardIssueRemainingLimit, 0);
};

export default cardIssueRemainingLimit;
