import axios from "axios";
import { Action } from "vuex";
import { RootState } from "@store/types";
import { WalletState } from "../types";

const exchangeFundsWallet: Action<WalletState, RootState> = async (
  _,
  { amount, fromCurrency, toCurrency }
) => {
  const { data } = await axios.post("/wallet/exchangeFunds", {
    amount,
    fromCurrency,
    toCurrency,
  });

  return data;
};

export default exchangeFundsWallet;
