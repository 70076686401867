import axios from "axios";
import { Action } from "vuex";
import { RootState } from "@store/types";
import { BlockchainState } from "../types";

const fetchTronWallet: Action<BlockchainState, RootState> = async () => {
  const { data } = await axios.put("/blockchain/tronWallet");

  return data;
};

export default fetchTronWallet;
