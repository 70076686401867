import { CardPaymentSystem, CardGroup } from "@/types/card";

export function getFallbackCardGroup({
  ownerEmail,
}: {
  ownerEmail: string;
}): CardGroup {
  return {
    id: null,
    name: "No group",
    owner: ownerEmail,
    style: {
      color: "#6d7480",
      border: "1px solid #6d7480",
      backgroundImage:
        "repeating-linear-gradient(-45deg, #97a2b1 0px, #97a2b1 1px, #acb8c9 1px, #acb8c9 9px, #97a2b1 9px, #97a2b1 10px, #a3afbe 10px, #a3afbe 16px)",
    },
  };
}

export function formatCardExpire(month: number, year: number): string {
  if (month > 0 && month < 10) {
    return `0${month} / ${year}`;
  }

  return `${month} / ${year}`;
}

export function formatCardNumber(value: string): string {
  return value
    .split("")
    .reduce((acc, char, index) => {
      if (index % 4 === 0) {
        acc += " ";
      }

      return acc + char;
    }, "")
    .trim();
}

export function getCardIconSrc(type?: string | null): string {
  type = type?.toUpperCase();

  if (type === CardPaymentSystem.VISA) {
    return require("@/assets/img/cards/visa.svg");
  }

  if (type === "AMEX") {
    return require("@/assets/img/cards/amex.svg");
  }

  if (type === CardPaymentSystem.MASTERCARD) {
    return require("@/assets/img/cards/mastercard.svg");
  }

  return "";
}

export function getCardPaymentSystem(
  cc: string
):
  | CardPaymentSystem
  | "AMEX"
  | "DISCOVER"
  | "DINERS"
  | "JCB"
  | "CHINA_UNION_PAY"
  | null {
  const amex = new RegExp("^3[47][0-9]{13}$");
  const cup1 = new RegExp("^62[0-9]{14}[0-9]*$");
  const cup2 = new RegExp("^81[0-9]{14}[0-9]*$");

  const visa = new RegExp("^4");
  const mastercard = new RegExp("^5");
  const mastercard2 = new RegExp("^2");

  const disco1 = new RegExp("^6011[0-9]{12}[0-9]*$");
  const disco2 = new RegExp("^62[24568][0-9]{13}[0-9]*$");
  const disco3 = new RegExp("^6[45][0-9]{14}[0-9]*$");

  const diners = new RegExp("^3[0689][0-9]{12}[0-9]*$");
  const jcb = new RegExp("^35[0-9]{14}[0-9]*$");

  if (visa.test(cc)) {
    return CardPaymentSystem.VISA;
  }

  if (amex.test(cc)) {
    return "AMEX";
  }

  if (mastercard.test(cc) || mastercard2.test(cc)) {
    return CardPaymentSystem.MASTERCARD;
  }

  if (disco1.test(cc) || disco2.test(cc) || disco3.test(cc)) {
    return "DISCOVER";
  }

  if (diners.test(cc)) {
    return "DINERS";
  }

  if (jcb.test(cc)) {
    return "JCB";
  }

  if (cup1.test(cc) || cup2.test(cc)) {
    return "CHINA_UNION_PAY";
  }

  return null;
}
