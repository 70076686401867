import { Action } from "vuex";
import axios from "axios";
import { RootState } from "@store/types";
import { ProfileState } from "../types";

const updateProfilePassword: Action<ProfileState, RootState> = async (
  { commit },
  payload
) => {
  const {
    data: { token, expires },
  } = await axios.post("/profile/password/update", payload);

  commit("auth/setUserInfo", { token, expires }, { root: true });
};

export default updateProfilePassword;
