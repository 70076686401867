import { RootState } from "@store/types";
import { Getter } from "vuex";
import { ProfileGetters, ProfileState } from "../types";

const cardIssueLimitExceeded: Getter<ProfileState, RootState> = (
  _,
  getters: ProfileGetters
) => {
  return getters.cardIssueRemainingLimit === 0;
};

export default cardIssueLimitExceeded;
