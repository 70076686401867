import { Vue, Component } from "vue-property-decorator";
import { setUserLang } from "@/lib/userLang";

@Component
export default class LangMixin extends Vue {
  protected changeLang(code: string): void {
    this.$vuetify.lang.current = code;
    setUserLang(code);
  }
}
