import { Getter } from "vuex";
import { RootState } from "@store/types";
import { Role } from "@/types/role";
import { ProfileGetters, ProfileState } from "../types";
import { hasRole } from "@/lib/role";

const userIsOwner: Getter<ProfileState, RootState> = (
  state,
  getters: ProfileGetters
) => {
  return hasRole(getters.profileAuthority, Role.ROLE_OWNER);
};

export default userIsOwner;
