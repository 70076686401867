import { Getter } from "vuex";
import { RootState } from "@store/types";
import { PartnerState, PartnerGetters } from "../types";

const partnersList: Getter<PartnerState, RootState> = (state) => {
  const getter: PartnerGetters["partnersList"] = (langCode) => {
    return state.list[langCode];
  };

  return getter;
};

export default partnersList;
