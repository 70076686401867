import { ActionTree } from "vuex";
import { NewsState } from "../types";
import { RootState } from "@store/types";
import fetchFeedNews from "./fetchFeedNews";
import fetchPopupNews from "./fetchPopupNews";
import markReadNewsInPopup from "./markReadNewsInPopup";
import markReadNewsInFeed from "./markReadNewsInFeed";

const actions: ActionTree<NewsState, RootState> = {
  fetchFeedNews,
  fetchPopupNews,
  markReadNewsInPopup,
  markReadNewsInFeed,
};

export default actions;
