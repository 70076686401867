import { ActionTree } from "vuex";
import { FaqState } from "../types";
import { RootState } from "@store/types";
import fetchFaq from "./fetchFaq";

const actions: ActionTree<FaqState, RootState> = {
  fetchFaq,
};

export default actions;
