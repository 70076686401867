import { Mutation } from "vuex";
import { CardGroupState } from "../types";
import { CardGroup } from "@/types/card";
import { nestedFreeze } from "@helpers";

const setCardGroups: Mutation<CardGroupState> = (
  state,
  payload: CardGroup[]
) => {
  state.groups.list = nestedFreeze(
    payload.concat([]).sort((a, b) => {
      const aName = a.name.toLowerCase();
      const bName = b.name.toLowerCase();

      if (aName < bName) {
        return -1;
      }

      if (aName > bName) {
        return 1;
      }

      return 0;
    })
  );
};

export default setCardGroups;
