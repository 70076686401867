import { Currency } from "./currency";
import { CardType } from "./card";

export enum BankType {
  DUMMY = "DUMMY",
  PEPLE = "PEPLE",
  ADSCARD = "ADSCARD",
  WALLESTER = "WALLESTER",
  STRADA = "STRADA",
  ADMEDIA = "ADMEDIA",
  HAYHAY = "HAYHAY",
  TODACARDS = "TODACARDS",
  CAPITALIST = "CAPITALIST",
}

export interface BankGroup {
  id: number;
  name: string;
  country: string;
  displayOrder: number;
}

export interface Bank {
  id: number;
  name: string;
  hidden: boolean;
  currency: Currency;
  bankType: BankType;
  bankGroup?: BankGroup;
  depositFee?: number;
  displayOrder?: number;
  transactionFee?: number;
  transactionFeeCurrency?: number;
  bankExchangeFeeMult?: number;
  extraBankExchangeFeeMult?: number;
  fixedFee?: number;
  fixedFeeThreshold?: number;
  cardIssueCost: number;
  country: string;
  allowedIssueCard: boolean;
  cardTypes: CardType[];
  isEnabled: boolean;
  declineTransactionFee?: number;
  minDepositAmount?: number;
  externalInfo?: {
    transKey: string;
    params?: Array<number | string>;
  }[];
  info: {
    zipInfo: string;
    "3ds": boolean;
    binId: string;
    paymentSystem: string;
    recommendations: {
      google: boolean;
      facebook: boolean;
      tikTok: boolean;
      seo: boolean;
      ai: boolean;
      hosting: boolean;
      taboola: boolean;
    };
  };
}
