import { MutationTree } from "vuex";
import { TeamState } from "../types";
import setTeamHierarchy from "./setTeamHierarchy";
import setTeamHierarchyLoading from "./setTeamHierarchyLoading";

const mutations: MutationTree<TeamState> = {
  setTeamHierarchyLoading,
  setTeamHierarchy,
};

export default mutations;
