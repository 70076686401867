import { ActionTree } from "vuex";
import { BlockchainState } from "../types";
import { RootState } from "@store/types";
import fetchTronWallet from "./fetchTronWallet";
import fetchBlockchainCommission from "./fetchBlockchainCommission";

const actions: ActionTree<BlockchainState, RootState> = {
  fetchTronWallet,
  fetchBlockchainCommission,
};

export default actions;
