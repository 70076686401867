import { MutationTree } from "vuex";
import { RootState } from "../types";
import setOnLine from "./setOnLine";
import setReady from "./setReady";
import setUtmLabels from "./setUtmLabels";
import setErrorStatus from "./setErrorStatus";

const mutations: MutationTree<RootState> = {
  setOnLine,
  setReady,
  setUtmLabels,
  setErrorStatus,
};

export default mutations;
