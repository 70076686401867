import { MutationTree } from "vuex";
import { MessageState } from "../types";

import addMessageToFeed from "./addMessageToFeed";
import setFeedMessagesList from "./setFeedMessagesList";
import setFeedMessagesLoading from "./setFeedMessagesLoading";

const mutations: MutationTree<MessageState> = {
  addMessageToFeed,
  setFeedMessagesList,
  setFeedMessagesLoading,
};

export default mutations;
