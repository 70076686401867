import "./index.css";

import axios, { AxiosError } from "axios";
import { PluginObject } from "vue";
import { googleRecaptchaModel } from "./model";
import getGrecaptcha from "./getGrecaptcha";

const GoogleRecaptchaPlugin: PluginObject<{
  siteKey: string;
}> = {
  install(Vue, options) {
    const siteKey = options?.siteKey;

    if (!siteKey) {
      throw new Error(
        `
          Не указан siteKey.
          Например, Vue.use(GoogleRecaptchaPlugin, { siteKey: 'siteKey' })
        `
      );
    }

    // const { googleRecaptchaModel } = await import("./model");

    googleRecaptchaModel.siteKey = siteKey;

    axios.interceptors.response.use(
      (resp) => {
        return resp;
      },
      (axiosError: AxiosError) => {
        const config = axiosError.config;
        const statusCode = axiosError.response?.status || 0;

        if (config.params && config.params["g-recaptcha-response"]) {
          return Promise.reject(axiosError);
        }

        if (statusCode !== 429) {
          return Promise.reject(axiosError);
        }

        return new Promise((resolve, reject) => {
          getGrecaptcha({ siteKey }).then((grecaptcha) => {
            if (grecaptcha) {
              try {
                document.body.classList.add("showed-grecaptcha-badge");

                grecaptcha
                  .execute(googleRecaptchaModel.siteKey, {
                    action: "submit",
                  })
                  .then((token) => {
                    document.body.classList.remove("showed-grecaptcha-badge");

                    axios({
                      ...config,
                      params: {
                        ...config.params,
                        "g-recaptcha-response": token,
                      },
                    })
                      .then(resolve)
                      .catch(reject);
                  });
              } catch (error) {
                console.error(error);
                reject(axiosError);
              }
            } else {
              reject(axiosError);
            }
          });
        });
      }
    );

    const GoogleRecaptcha = () =>
      import("./components/GoogleRecaptcha/GoogleRecaptcha.vue");

    Vue.component("GoogleRecaptcha", GoogleRecaptcha);
  },
};

export default GoogleRecaptchaPlugin;
