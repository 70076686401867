import { Getter } from "vuex";
import { RootState } from "@store/types";
import { Promocode, PromocodeType, PromocodeStatus } from "@/types/promocode";
import { ProfileState } from "../types";

const profileFreeCardsTotalPromocode: Getter<ProfileState, RootState> = (
  state
) => {
  return state.promocodes.list.reduce<Pick<Promocode, "bonus" | "bonusUsed">>(
    (acc, { type, bonus, bonusUsed, status }) => {
      if (
        type !== PromocodeType.FREE_CARDS_BONUS ||
        status !== PromocodeStatus.ACTIVE
      ) {
        return acc;
      }

      acc.bonus += bonus;
      acc.bonusUsed += bonusUsed;

      return acc;
    },
    {
      bonus: 0,
      bonusUsed: 0,
    }
  );
};

export default profileFreeCardsTotalPromocode;
