export enum MessageType {
  FACEBOOK_CODE_EVENT = "FacebookCodeEvent",
}

export type Message = {
  id: string;
  type: MessageType.FACEBOOK_CODE_EVENT;
  createdAt: string;
  closedAt?: string;
  content: {
    code: string;
    amount: number;
    cardId: number;
    cardMask: string;
  };
};
