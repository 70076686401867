import { Action } from "vuex";
import { RootState } from "@store/types";
import { MessageState } from "../types";
import API from "@api";

const markReadMessagesInFeed: Action<MessageState, RootState> = async (
  { commit, state },
  { ids }: { ids: string[] }
) => {
  commit(
    "setFeedMessagesList",
    state.feed.list.map((message) => ({
      ...message,
      closedAt: ids.includes(message.id)
        ? new Date().toString()
        : message.closedAt,
    }))
  );
  API.feed.closeFeedMessages({ ids });
};

export default markReadMessagesInFeed;
