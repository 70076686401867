import { RootState } from "@store/types";
import { GetterTree } from "vuex";
import { FaqState } from "../types";
import faqList from "./faqList";
import faqLoading from "./faqLoading";

const getters: GetterTree<FaqState, RootState> = {
  faqList,
  faqLoading,
};

export default getters;
