import { ActionTree } from "vuex";
import { MessageState } from "../types";
import { RootState } from "@store/types";
import fetchFeedMessages from "./fetchFeedMessages";
import markReadMessagesInFeed from "./markReadMessagesInFeed";

const actions: ActionTree<MessageState, RootState> = {
  fetchFeedMessages,
  markReadMessagesInFeed,
};

export default actions;
