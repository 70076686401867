import { Mutation } from "vuex";
import { ProfileState } from "../types";

const setPromocodesLoading: Mutation<ProfileState> = (
  state,
  payload: boolean
) => {
  state.promocodes.loading = payload;
};

export default setPromocodesLoading;
