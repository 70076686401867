import { Component, Vue } from "vue-property-decorator";
import events from "../../events";
import { NotificationOptions } from "../../types";

@Component
export default class Notifications extends Vue {
  private notifications: Readonly<(NotificationOptions & { id: number })[]> =
    [];

  private closeNotification(id: number) {
    this.notifications = Object.freeze(
      this.notifications.filter((notification) => notification.id !== id)
    );
    window.clearTimeout(id);
  }

  private mounted() {
    events.$on(
      "add",
      ({ duration = 5e3, ...notification }: NotificationOptions) => {
        const id = window.setTimeout(() => {
          this.closeNotification(id);
        }, duration);

        this.notifications = Object.freeze([
          { ...notification, id },
          ...this.notifications,
        ]);
      }
    );
  }
}
