import { RootState } from "@store/types";
import { GetterTree } from "vuex";
import { WarningState } from "../types";

import warningsGroupByTypes from "./warningsGroupByTypes";
import loadingFeedWarning from "./loadingFeedWarning";

const getters: GetterTree<WarningState, RootState> = {
  warningsGroupByTypes,
  loadingFeedWarning,
};

export default getters;
