import { Getter } from "vuex";
import { RootState } from "@store/types";
import { PartnerState, PartnerGetters } from "../types";

const partnersLoading: Getter<PartnerState, RootState> = (state) => {
  const getter: PartnerGetters["partnersLoading"] = (langCode) => {
    return state.loading[langCode];
  };

  return getter;
};

export default partnersLoading;
