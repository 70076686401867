import Vue from "vue";
import locales from "./locales";

import Vuetify, {
  VCheckbox,
  VSimpleCheckbox,
  VDialog,
  VSpacer,
  VDivider,
  VTooltip,
  VBadge,
  VData,
  VDatePicker,
  VVirtualTable,
  VPicker,
  VVirtualScroll,
  VDatePickerHeader,
  VFadeTransition,
  VExpandTransition,
  VScaleTransition,
  VScrollXTransition,
  VScrollYTransition,
  VTabTransition,
  VFabTransition,
  VMenuTransition,
  VSlideYTransition,
  VExpandXTransition,
  VDialogTransition,
  VSlideXTransition,
  VSlideYReverseTransition,
  VScrollYReverseTransition,
  VApp,
  VMain,
  VMenu,
  VProgressCircular,
  VProgressLinear,
  VDataTable,
  VCard,
  VTextField,
  VTextarea,
  VAvatar,
  VNavigationDrawer,
  VAppBar,
  VAppBarNavIcon,
  VBottomSheet,
  VSheet,
  VForm,
  VAutocomplete,
  VCombobox,
  VCardText,
  VCardActions,
  VBtn,
  VIcon,
  VOverlay,
  VResponsive,
  VImg,
  VList,
  VListItem,
  VSubheader,
  VListGroup,
  VSelect,
  VMessages,
  VLabel,
  VInput,
  VDataTableHeader,
  VSimpleTable,
  VDataFooter,
  VToolbar,
  VChip,
  VAlert,
} from "vuetify/lib";
import { Ripple } from "vuetify/lib/directives";
import { getUserLang } from "@/lib/userLang";
import { MOBILE_BREAKPOINT, SCROLLBAR_WIDTH } from "@config/base";
import icons from "./icons";

Vue.use(Vuetify, {
  components: {
    VCheckbox,
    VSimpleCheckbox,
    VDialog,
    VDivider,
    VTooltip,
    VBadge,
    VSpacer,
    VData,
    VDatePicker,
    VVirtualTable,
    VPicker,
    VVirtualScroll,
    VDatePickerHeader,
    VFadeTransition,
    VExpandTransition,
    VScaleTransition,
    VScrollXTransition,
    VScrollYTransition,
    VTabTransition,
    VFabTransition,
    VMenuTransition,
    VSlideYTransition,
    VExpandXTransition,
    VDialogTransition,
    VSlideXTransition,
    VSlideYReverseTransition,
    VScrollYReverseTransition,
    VApp,
    VMain,
    VMenu,
    VProgressCircular,
    VProgressLinear,
    VDataTable,
    VCard,
    VTextField,
    VTextarea,
    VAvatar,
    VNavigationDrawer,
    VAppBar,
    VAppBarNavIcon,
    VBottomSheet,
    VSheet,
    VForm,
    VAutocomplete,
    VCombobox,
    VCardText,
    VCardActions,
    VBtn,
    VIcon,
    VOverlay,
    VResponsive,
    VImg,
    VList,
    VListItem,
    VSubheader,
    VListGroup,
    VSelect,
    VMessages,
    VLabel,
    VInput,
    VDataTableHeader,
    VSimpleTable,
    VDataFooter,
    VToolbar,
    VChip,
    VAlert,
  },
  directives: {
    Ripple,
  },
});

export default new Vuetify({
  icons: {
    values: icons,
  },
  lang: {
    current: getUserLang(),
    locales,
  },

  breakpoint: {
    thresholds: {
      md: 1280 + SCROLLBAR_WIDTH,
      lg: 1860 + SCROLLBAR_WIDTH,
    },
    mobileBreakpoint: MOBILE_BREAKPOINT,
    scrollBarWidth: SCROLLBAR_WIDTH,
  },
  theme: {
    options: {
      cspNonce: "dQw4w9WgXcQ",
    },
    themes: {
      light: {
        primary: "#4E96FA",
        error: "#FF515F",
        success: "#45DF82",
        warning: "#FFC008",
      },
    },
  },
});
