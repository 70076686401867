import axios from "axios";
import { Action } from "vuex";
import { RootState } from "@store/types";
import { WalletState } from "../types";

const fetchExchangeRates: Action<WalletState, RootState> = async () => {
  const { data } = await axios.get("/wallet/exchangeRates");

  return data;
};

export default fetchExchangeRates;
