import { Action } from "vuex";
import axios from "axios";
import { RootState } from "@store/types";
import { BillingState } from "../types";
import { BillingRefill } from "@/types/billing";

const fetchBillingRefills: Action<BillingState, RootState> = async () => {
  const { data } = await axios.get("/billing/refill/list");

  return (data as BillingRefill[]).map((item) => ({
    ...item,
    createdAt: new Date(item.createdAt).getTime(),
  }));
};

export default fetchBillingRefills;
