import { RootState } from "@store/types";
import { Getter } from "vuex";
import { CardGroupState } from "../types";
import { ProfileGetters } from "@store/modules/profile/types";

const cardGroups: Getter<CardGroupState, RootState> = (
  state,
  _getters,
  _rootState,
  rootGetters
) => {
  const profileEmail = rootGetters[
    "profile/profileEmail"
  ] as ProfileGetters["profileEmail"];

  return state.groups.list.filter(({ owner }) => owner === profileEmail);
};

export default cardGroups;
