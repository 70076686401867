var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_vm.componentName,_vm._g(_vm._b({ref:"list",tag:"component",staticClass:"base-dropdown",attrs:{"menu-props":{
    offsetY: true,
    closeOnClick: _vm.type === 'select',
    contentClass: _vm.menuPropsContentClass,
    nudgeTop: '0',
    nudgeBottom: '0',
    transition: 'slide-y-reverse-transition',
    ..._vm.localMenuProps,
  }},scopedSlots:_vm._u([(_vm.$scopedSlots['selection'])?{key:"selection",fn:function(slotProps){return [_vm._t("selection",null,null,slotProps)]}}:null,(_vm.$scopedSlots['prepend-inner'])?{key:"prepend-inner",fn:function(){return [_vm._t("prepend-inner")]},proxy:true}:null],null,true)},'component',_vm.$attrs,false),_vm.$listeners))
}
var staticRenderFns = []

export { render, staticRenderFns }