import { Getter } from "vuex";
import { RootState } from "@store/types";
import { WalletGetters, WalletState } from "../types";

const walletBalance: Getter<WalletState, RootState> = (
  _,
  { walletsList }: WalletGetters
) => {
  return walletsList.reduce((acc, { balance, currency }) => {
    acc[currency] = balance;

    return acc;
  }, {} as WalletGetters["walletBalance"]);
};

export default walletBalance;
