import { Mutation } from "vuex";
import { MessageState } from "../types";

const addMessageToFeed: Mutation<MessageState> = (state, payload) => {
  state.feed.list = Object.freeze(
    state.feed.list
      .filter((message) => message.id !== payload.id)
      .concat(payload)
  );
};

export default addMessageToFeed;
