import { Action } from "vuex";
import axios from "axios";
import { RootState } from "@store/types";
import { NewsState } from "../types";

const markReadNewsInFeed: Action<NewsState, RootState> = async (
  { commit, state },
  { newsId }
) => {
  commit(
    "setFeedNewsList",
    state.feed.list.map((item) => ({ ...item, isRead: true }))
  );

  const {
    data: { items },
  } = await axios.post("/news/feed", null, {
    params: { newsId },
  });

  commit("setFeedNewsList", items);

  return items;
};

export default markReadNewsInFeed;
