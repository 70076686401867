import { loadScript } from "@helpers";

type Grecaptcha =
  | (ReCaptchaV2.ReCaptcha & {
      enterprise: ReCaptchaV2.ReCaptcha;
    })
  | null;

let loadScriptPromise: Promise<Grecaptcha> | null = null;

export default async function getGrecaptcha({
  siteKey,
}: {
  siteKey: string;
}): Promise<Grecaptcha> {
  loadScriptPromise =
    loadScriptPromise ??
    new Promise((resolve) => {
      loadScript({
        src: `https://www.google.com/recaptcha/api.js?render=${siteKey}`,
      })
        .then(() => {
          grecaptcha.ready(() => {
            resolve(grecaptcha);
          });
        })
        .catch(() => {
          resolve(null);
        });
    });

  await loadScriptPromise;

  return grecaptcha;
}
