import Vue, { PluginObject } from "vue";

import BaseDropDown from "@/components/BaseDropDown/BaseDropDown.vue";
import Menu from "@/components/Menu/Menu.vue";
import FormField from "@/components/FormField/FormField.vue";
import MobileScreen from "@/components/MobileScreen/MobileScreen.vue";

const GlobalComponentsPlugin: PluginObject<undefined> = {
  install() {
    const components = {
      BaseDropDown,
      Menu,
      FormField,
      MobileScreen,
      BaseImg: () => import("@/components/BaseImg/BaseImg.vue"),
      Avatar: () => import("@/components/Avatar/Avatar.vue"),
      FilterTableTopBar: () =>
        import("@/components/FilterTableTopBar/FilterTableTopBar.vue"),
      DateRangePicker: () =>
        import("@/components/DateRangePicker/DateRangePicker.vue"),
      DesktopScreen: () =>
        import("@/components/DesktopScreen/DesktopScreen.vue"),
      CardGroupList: () =>
        import("@/components/CardGroupList/CardGroupList.vue"),
      Dialog: () => import("@/components/Dialog/Dialog.vue"),
      Trans: () => import("@/components/Trans/Trans.vue"),
      LangSwitcher: () => import("@/components/LangSwitcher/LangSwitcher.vue"),
      LazyComponent: () =>
        import("@/components/LazyComponent/LazyComponent.vue"),
      LottieBanner: () => import("@/components/LottieBanner/LottieBanner.vue"),
      CardGroupMultiselect: () =>
        import("@/components/CardGroupMultiselect/CardGroupMultiselect.vue"),
      BottomSheet: () => import("@/components/BottomSheet/BottomSheet.vue"),
      PasswordTextField: () =>
        import("@/components/PasswordTextField/PasswordTextField.vue"),
      CardIssueLimit: () =>
        import("@/components/CardIssueLimit/CardIssueLimit.vue"),
      CardsMultiselect: () =>
        import("@/components/CardsMultiselect/CardsMultiselect.vue"),
      MaskedText: () => import("@/components/MaskedText/MaskedText.vue"),
      ExpandCardTile: () =>
        import("@/components/ExpandCardTile/ExpandCardTile.vue"),
      MultiSelect: () => import("@/components/MultiSelect/MultiSelect.vue"),
      InfiniteList: () => import("@/components/InfiniteList/InfiniteList.vue"),
      TechnicalScreen: () =>
        import("@/components/TechnicalScreen/TechnicalScreen.vue"),
      DataTable: () => import("@/components/DataTable/DataTable.vue"),
      ConfirmDialog: () =>
        import("@/components/ConfirmDialog/ConfirmDialog.vue"),
      ConfirmCard: () => import("@/components/ConfirmCard/ConfirmCard.vue"),
      WalletTransferFunds: () =>
        import("@/components/WalletTransferFunds/WalletTransferFunds.vue"),
      LockedPageBanner: () =>
        import("@/components/LockedPageBanner/LockedPageBanner.vue"),
      TeamMembersSelect: () =>
        import("@/components/TeamMembersSelect/TeamMembersSelect.vue"),
    };

    components.CardIssueLimit();
    components.WalletTransferFunds();

    Object.entries(components).forEach(([id, constructor]) => {
      Vue.component(id, constructor);
    });
  },
};

export default GlobalComponentsPlugin;
