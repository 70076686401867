import axios from "axios";
import { Action } from "vuex";
import { RootState } from "@store/types";
import { CardState } from "../types";
import { UserGetters } from "@store/modules/user/types";

const fetchBatchIssueCardList: Action<CardState, RootState> = async ({
  rootGetters,
}) => {
  const userIsGhost = rootGetters[
    "user/userIsGhost"
  ] as UserGetters["userIsGhost"];

  if (userIsGhost) {
    return [];
  }

  const { data } = await axios.get("/card/batchIssue/list");

  return data;
};

export default fetchBatchIssueCardList;
