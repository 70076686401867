import { Module } from "vuex";
import { RootState } from "@store/types";
import { ProfileState } from "./types";
import state from "./state";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const module: Module<ProfileState, RootState> = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};

export default module;
