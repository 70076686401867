import { Mutation } from "vuex";
import { FaqState } from "../types";
import { LangCode } from "@/types/lang";
import { FaqSection } from "@/types/faq";
import { nestedFreeze } from "@helpers";

const setFaqList: Mutation<FaqState> = (
  state,
  { langCode, value }: { langCode: LangCode; value: FaqSection[] }
) => {
  state.list[langCode] = nestedFreeze(value);
};

export default setFaqList;
