import { Currency } from "./currency";

export enum PaymentMethodId {
  CAPITALIST_USD = "capitalist_usd",
  CAPITALIST_BTC = "capitalist_btc",
  CAPITALIST_USDT_ERC20 = "capitalist_usdt_erc20",
  CAPITALIST_USDT_TRC20 = "capitalist_usdt_trc20",
  USDT_TRANSFER_HANDLE = "usdt_transfer_handle",
  USDT_TRANSFER = "tron_usdt",
}

export interface PaymentMethod {
  id: PaymentMethodId;
  name: string;
  comission: number;
  availableCurrency: Currency[];
  maxAmount: number;
  minAmount: number;
}

export interface UsdtTransferPaymentMethod {
  id: PaymentMethodId.USDT_TRANSFER;
  name: string;
  comission: number;
  fixedComission: number;
  currency: Currency;
  minAmount: number;
  availableCurrency: Currency[];
  externalFields: {
    TRC: string;
  };
}

export interface UsdtTransferHandlePaymentMethod {
  id: PaymentMethodId.USDT_TRANSFER_HANDLE;
  name: string;
  comission: number;
  fixedComission: number;
  currency: Currency;
  minAmount: number;
  externalFields: {
    TRC: string;
    ERC: string;
  };
}

export type BillingPaymentMethod =
  | PaymentMethod
  | UsdtTransferPaymentMethod
  | UsdtTransferHandlePaymentMethod;

export enum BillingInvoiceStatus {
  CREATED = "created",
  SUCCESS = "success",
  ERROR = "error",
  EXPIRED = "expired",
}

export interface BillingRefill {
  createdAt: number;
  paymentMethod: string;
  amount: number;
  currency: Currency;
}

export interface BillingInvoice {
  uuid: string;
  createdAt: string;
  paymentMethod: PaymentMethodId;
  amount: number;
  currency: Currency;
  status: BillingInvoiceStatus;
}
