import { MutationTree } from "vuex";
import { ProfileState } from "../types";
import setProfile from "./setProfile";
import setProfileLanguage from "./setProfileLanguage";
import setStatus from "./setStatus";
import setPromocodes from "./setPromocodes";
import setPromocodesLoading from "./setPromocodesLoading";

const mutations: MutationTree<ProfileState> = {
  setProfile,
  setProfileLanguage,
  setStatus,
  setPromocodes,
  setPromocodesLoading,
};

export default mutations;
