import { Action } from "vuex";
import API from "@api";
import { RootState } from "@store/types";
import { TeamState } from "../types";

const fetchTeamInvite: Action<TeamState, RootState> = () => {
  return API.team.fetchTeamInvite();
};

export default fetchTeamInvite;
