import { RootState } from "@store/types";
import { GetterTree } from "vuex";
import { AuthState } from "../types";
import authenticated from "./authenticated";
import token from "./token";

const getters: GetterTree<AuthState, RootState> = {
  authenticated,
  token,
};

export default getters;
