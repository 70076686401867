import { Action } from "vuex";
import axios, { AxiosResponse } from "axios";
import { RootState } from "@store/types";
import { CardGroupState } from "../types";
import { CardGroup } from "@/types/card";
import { ProfileGetters } from "@store/modules/profile/types";
import { getFallbackCardGroup } from "@/lib/creaditCard";

let fetchCardGroupsPromise: Promise<AxiosResponse<CardGroup[]>> | null = null;

const fetchCardGroups: Action<CardGroupState, RootState> = async ({
  commit,
  rootGetters,
}) => {
  const userIsMediabuyer = rootGetters[
    "profile/userIsMediabuyer"
  ] as ProfileGetters["userIsMediabuyer"];

  const profileEmail = rootGetters[
    "profile/profileEmail"
  ] as ProfileGetters["profileEmail"];

  if (fetchCardGroupsPromise) {
    return fetchCardGroupsPromise;
  }

  commit("setCardGroupsLoading", true);

  try {
    fetchCardGroupsPromise = axios.get<CardGroup[]>("/card/group/list", {
      params: {
        includeTeamGroups: !userIsMediabuyer,
      },
    });

    const { data } = await fetchCardGroupsPromise;

    data.push(
      getFallbackCardGroup({
        ownerEmail: profileEmail,
      })
    );

    commit("setCardGroups", data);
    commit("setCardGroupsLoading", false);
  } catch {
    commit("setCardGroupsLoading", false);
  } finally {
    fetchCardGroupsPromise = null;
  }
};

export default fetchCardGroups;
