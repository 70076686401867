import { PluginObject } from "vue";
import autoAnimate from "@formkit/auto-animate";

const AutoAnimatePlugin: PluginObject<{
  siteKey: string;
}> = {
  install(Vue) {
    Vue.directive("auto-animate", {
      inserted(el, binding) {
        autoAnimate(el, binding.value || {});
      },
    });
  },
};

export default AutoAnimatePlugin;
