import { FaqState } from "./types";
import { LangCode } from "@/types/lang";

const state = (): FaqState => ({
  list: {
    [LangCode.ENGLISH]: [],
    [LangCode.RUSSIAN]: [],
    [LangCode.UKRAINIAN]: [],
  },
  loading: {
    [LangCode.ENGLISH]: false,
    [LangCode.RUSSIAN]: false,
    [LangCode.UKRAINIAN]: false,
  },
});

export default state;
