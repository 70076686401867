import { RootState } from "@store/types";
import { GetterTree } from "vuex";
import { CardState } from "../types";

import cardGroups from "./cardGroups";
import cardsByType from "./cardsByType";
import cardsList from "./cardsList";
import loadedCards from "./loadedCards";
import loadingCards from "./loadingCards";
import postpaidCards from "./postpaidCards";
import prepaidCards from "./prepaidCards";
import noIssuedCards from "./noIssuedCards";

const getters: GetterTree<CardState, RootState> = {
  cardGroups,
  cardsByType,
  cardsList,
  loadedCards,
  loadingCards,
  postpaidCards,
  prepaidCards,
  noIssuedCards,
};

export default getters;
