import { Action } from "vuex";
import axios from "axios";
import { RootState } from "@store/types";
import { AuthState } from "../types";

const checkSecretCode: Action<AuthState, RootState> = async (_, { secret }) => {
  const { data: checked } = await axios.post("/auth/secret/check", {
    secret,
  });

  return checked ? Promise.resolve(true) : Promise.reject(false);
};

export default checkSecretCode;
