import { RootState } from "@store/types";
import { Getter } from "vuex";
import { BankState } from "../types";
import { CardType } from "@/types/card";

const banksWithPrepaidCards: Getter<BankState, RootState> = (state) => {
  return state.userBanks.list.filter(
    (bank) => bank.cardTypes.includes(CardType.PREPAID) && bank.isEnabled
  );
};

export default banksWithPrepaidCards;
