import { RootState } from "@store/types";
import { GetterTree } from "vuex";
import { WalletState } from "../types";

import defaultWalletCurrency from "./defaultWalletCurrency";
import hasWalletCurrency from "./hasWalletCurrency";
import loadedWalletsCurrencies from "./loadedWalletsCurrencies";
import wallet from "./wallet";
import walletsList from "./walletsList";
import walletBalance from "./walletBalance";
import walletsCurrencies from "./walletsCurrencies";

const getters: GetterTree<WalletState, RootState> = {
  defaultWalletCurrency,
  hasWalletCurrency,
  loadedWalletsCurrencies,
  wallet,
  walletsList,
  walletBalance,
  walletsCurrencies,
};

export default getters;
