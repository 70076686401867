import { RootState } from "@store/types";
import { Getter } from "vuex";
import { ProfileState, ProfileGetters } from "../types";
import { Role } from "@/types/role";

const accessToProfilePage: Getter<ProfileState, RootState> = (
  _state,
  { userHasRole }: ProfileGetters
) => {
  return userHasRole([
    Role.ROLE_MEDIABUYER,
    Role.ROLE_OWNER,
    Role.ROLE_TEAMLEAD,
    Role.ROLE_NETWORK,
  ]);
};

export default accessToProfilePage;
