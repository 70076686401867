import { RootState } from "@store/types";
import { Getter } from "vuex";
import { CardState, CardGetters } from "../types";

const prepaidCards: Getter<CardState, RootState> = (
  _,
  getters: CardGetters
) => {
  return getters.cardsByType.prepaidCards;
};

export default prepaidCards;
