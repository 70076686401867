import { ActionTree } from "vuex";
import { WarningState } from "../types";
import { RootState } from "@store/types";

import fetchFeedWarning from "./fetchFeedWarning";
import hideWarnings from "./hideWarnings";

const actions: ActionTree<WarningState, RootState> = {
  fetchFeedWarning,
  hideWarnings,
};

export default actions;
