import { MutationTree } from "vuex";
import { WalletState } from "../types";
import setWallets from "./setWallets";
import setWalletsLoaded from "./setWalletsLoaded";
import setWalletsLoading from "./setWalletsLoading";

const mutations: MutationTree<WalletState> = {
  setWallets,
  setWalletsLoaded,
  setWalletsLoading,
};

export default mutations;
