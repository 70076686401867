export enum WarningType {
  CARD_SUSPENDED = "CARD_SUSPENDED",
}

export type Warning = {
  id: number;
  type: WarningType.CARD_SUSPENDED;
  params: {
    cardIds: number[];
  };
};

export type WarningGroupedByType = {
  ids: number[];
  type: WarningType.CARD_SUSPENDED;
  params: {
    cardIds: number[];
  };
};
