import { Getter } from "vuex";
import { RootState } from "@store/types";
import { ProfileState, ProfileGetters } from "../types";

const profilePromocode: Getter<ProfileState, RootState> = (state) => {
  const getter: ProfileGetters["profilePromocode"] = ({ type }) => {
    return state.promocodes.list.find(
      (promocode) =>
        type === promocode.type && promocode.bonus > promocode.bonusUsed
    );
  };

  return getter;
};

export default profilePromocode;
