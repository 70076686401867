import { Action } from "vuex";
import axios from "axios";
import { RootState } from "@store/types";
import { TeamState } from "../types";

const updateTeamMember: Action<TeamState, RootState> = async (
  _,
  { email, label, active, cardIssueLimit, secondFactorCode }
) => {
  const { data } = await axios.post("/team/member/update", {
    email,
    label,
    active,
    cardIssueLimit,
    secondFactorCode: secondFactorCode || "",
  });

  return data;
};

export default updateTeamMember;
