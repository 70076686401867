import { Action } from "vuex";
import axios from "axios";
import { RootState } from "@store/types";
import { BillingState } from "../types";

const fetchBillingInvoices: Action<BillingState, RootState> = async () => {
  const { data } = await axios.get("/billing/invoice/list");

  return data;
};

export default fetchBillingInvoices;
