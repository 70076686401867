import { Action } from "vuex";
import axios from "axios";
import { RootState } from "@store/types";
import { NewsState } from "../types";

const markReadNewsPopup: Action<NewsState, RootState> = async (
  _,
  { newsId }
) => {
  const { data } = await axios.post("/news/popup", null, {
    params: { newsId },
  });

  return data;
};

export default markReadNewsPopup;
