import { Getter } from "vuex";
import { getFullName } from "@helpers";
import { RootState } from "@store/types";
import { ProfileState } from "../types";

const profileFullName: Getter<ProfileState, RootState> = (state) => {
  return state.profile
    ? getFullName({
        firstName: state.profile.firstName,
        lastName: state.profile.lastName,
        fallback: state.profile.email,
      })
    : "";
};

export default profileFullName;
