import { Action } from "vuex";
import { RootState } from "@store/types";
import { CardState } from "../types";
import axios from "axios";

const fetchCardNumber: Action<CardState, RootState> = async (_, { id }) => {
  const keyPair = await window.crypto.subtle.generateKey(
    {
      name: "RSA-OAEP",
      modulusLength: 2048,
      publicExponent: new Uint8Array([1, 0, 1]),
      hash: "SHA-256",
    },
    true,
    ["encrypt", "decrypt"]
  );

  const publicKey = await window.crypto.subtle.exportKey(
    "spki",
    keyPair.publicKey
  );

  const publicKeyBase64 = btoa(
    String.fromCharCode(...new Uint8Array(publicKey))
  );

  const { data } = await axios.post<string>("/card/number", {
    id,
    publicKey: publicKeyBase64,
  });

  const decrypted = await window.crypto.subtle.decrypt(
    {
      name: "RSA-OAEP",
    },
    keyPair.privateKey,
    Uint8Array.from(atob(data), (c) => c.charCodeAt(0))
  );

  return new TextDecoder().decode(decrypted);
};

export default fetchCardNumber;
