import { Action } from "vuex";
import { RootState } from "@store/types";
import { ProfileState } from "../types";
import axios from "axios";

const updateProfileAvatar: Action<ProfileState, RootState> = async (
  context,
  payload
) => {
  await axios.post("/profile/avatar/update", payload);
};

export default updateProfileAvatar;
