import { RootState } from "@store/types";
import { GetterTree } from "vuex";
import { UserState } from "../types";
import userIsGhost from "./userIsGhost";
import userWebPreferences from "./userWebPreferences";

const getters: GetterTree<UserState, RootState> = {
  userIsGhost,
  userWebPreferences,
};

export default getters;
