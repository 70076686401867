import { MutationTree } from "vuex";
import { SidebarState } from "../types";

import toggleSidebarMinimize from "./toggleSidebarMinimize";

const mutations: MutationTree<SidebarState> = {
  toggleSidebarMinimize,
};

export default mutations;
