import { Mutation } from "vuex";
import { SidebarState } from "../types";

const toggleSidebarMinimize: Mutation<SidebarState> = (
  state,
  payload = !state.minimize
) => {
  state.minimize = payload;
};

export default toggleSidebarMinimize;
