import { Action } from "vuex";
import { RootState } from "@store/types";
import { StatisticState } from "../types";
import API from "@api";

const fetchStatisticDeclines: Action<StatisticState, RootState> = async ({
  state,
  commit,
}) => {
  if (state.declines.loading) {
    return;
  }

  commit("setStatisticDeclinesLoading", true);

  try {
    const data = await API.statistic.fetchStatisticDeclines();

    commit("setStatisticDeclines", data);
  } finally {
    commit("setStatisticDeclinesLoading", false);
  }
};

export default fetchStatisticDeclines;
