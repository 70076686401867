import { Getter } from "vuex";
import { RootState } from "@store/types";
import { FaqState, FaqGetters } from "../types";

const faqList: Getter<FaqState, RootState> = (state) => {
  const getter: FaqGetters["faqList"] = (langCode) => {
    return state.list[langCode];
  };

  return getter;
};

export default faqList;
