import axios from "axios";
import { Action } from "vuex";
import { RootState } from "@store/types";
import { ProfileState } from "../types";

const profileRefreshToken: Action<ProfileState, RootState> = async ({
  commit,
  rootGetters,
}) => {
  const {
    data: { token, expires },
  } = await axios.post("/profile/token/refresh", null, {
    headers: { "X-Auth-Token": rootGetters["auth/token"] },
  });

  commit("auth/setUserInfo", { token, expires }, { root: true });

  return token;
};

export default profileRefreshToken;
