import { Action } from "vuex";
import axios from "axios";
import { RootState } from "@store/types";
import { removeUserInfo } from "@/lib/auth";
import { AuthState } from "../types";

const logOut: Action<AuthState, RootState> = async (_, payload = {}) => {
  const { sendLogoutRequest = true } = payload;

  try {
    if (sendLogoutRequest) {
      await axios.get("/profile/logout", { isAxiosRetry: false });
    }
  } finally {
    removeUserInfo();
    window.location.reload();
  }
};

export default logOut;
