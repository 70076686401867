import { RootState } from "@store/types";
import { Getter } from "vuex";
import { BankState, BankGetters } from "../types";

const teamBanks: Getter<BankState, RootState> = (
  state,
  getters: BankGetters
) => {
  return getters.allBanks.filter((bank) =>
    state.teamBanks.list.includes(bank.id)
  );
};

export default teamBanks;
