import { ModuleTree } from "vuex";
import { RootState } from "@store/types";

import auth from "./auth";
import sidebar from "./sidebar";
import statistic from "./statistic";
import card from "./card";
import profile from "./profile";
import bank from "./bank";
import billing from "./billing";
import team from "./team";
import wallet from "./wallet";
import cashflow from "./cashflow";
import blockchain from "./blockchain";
import user from "./user";
import faq from "./faq";
import message from "./message";
import news from "./news";
import partner from "./partner";
import warning from "./warning";

const modules: ModuleTree<RootState> = {
  auth,
  sidebar,
  statistic,
  card,
  profile,
  bank,
  billing,
  team,
  wallet,
  cashflow,
  blockchain,
  user,
  faq,
  message,
  news,
  partner,
  warning,
};

export default modules;
