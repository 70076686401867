import { UserWebPreferencesKey, UserWebPreferencesValue } from "@/types/user";
import { Mutation } from "vuex";
import { UserState } from "../types";

const setUserWebPreferences: Mutation<UserState> = (
  state,
  {
    key,
    value,
    loading,
    loaded,
  }: {
    key: UserWebPreferencesKey;
    value?: UserWebPreferencesValue;
    loading?: boolean;
    loaded?: boolean;
  }
) => {
  state.webpreferences[key].loading =
    loading ?? state.webpreferences[key].loading;

  state.webpreferences[key].loaded = loaded ?? state.webpreferences[key].loaded;

  state.webpreferences[key].value =
    Object.freeze(value) ?? state.webpreferences[key].value;
};

export default setUserWebPreferences;
