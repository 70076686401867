import { UserWebPreferencesKey } from "@/types/user";
import { UserState } from "./types";
import { getUserInfo } from "@/lib/auth";

export default (): UserState => ({
  isGhost: getUserInfo().isGhost,
  webpreferences: {
    [UserWebPreferencesKey.BALANCE_CARDS]: {
      loading: false,
      loaded: false,
      value: {},
    },
    [UserWebPreferencesKey.LIMIT_CARDS]: {
      loading: false,
      loaded: false,
      value: {},
    },
    [UserWebPreferencesKey.TRANSACTIONS]: {
      loading: false,
      loaded: false,
      value: {},
    },
    [UserWebPreferencesKey.CASH_FLOW]: {
      loading: false,
      loaded: false,
      value: {},
    },
    [UserWebPreferencesKey.TEAM]: {
      loading: false,
      loaded: false,
      value: {},
    },
    [UserWebPreferencesKey.REFILL]: {
      loading: false,
      loaded: false,
      value: {},
    },
    [UserWebPreferencesKey.STATISTIC]: {
      loading: false,
      loaded: false,
      value: {},
    },
    [UserWebPreferencesKey.STATISTIC_BY_STATUS]: {
      loading: false,
      loaded: false,
      value: {},
    },
    [UserWebPreferencesKey.WALLET_TRANSFER_FUNDS]: {
      loading: false,
      loaded: false,
      value: {},
    },
    [UserWebPreferencesKey.FAVORITE_BANKS_ISSUE_CARD]: {
      loading: false,
      loaded: false,
      value: {},
    },
    [UserWebPreferencesKey.HEADER_WALLETS]: {
      loading: false,
      loaded: false,
      value: {},
    },
  },
});
