import unionWith from "lodash.unionwith";
import { Getter } from "vuex";
import { RootState } from "@store/types";
import { WarningState, WarningGetters } from "../types";
import { WarningGroupedByType, WarningType } from "@/types/warning";

const warningsGroupByTypes: Getter<WarningState, RootState> = (state) => {
  const getter: WarningGetters["warningsGroupByTypes"] = (warningTypes) => {
    return Object.values(
      state.feed.list.reduce<Record<string, WarningGroupedByType>>(
        (acc, { type, id, params }) => {
          if (Array.isArray(warningTypes) && !warningTypes.includes(type)) {
            return acc;
          }

          switch (type) {
            case WarningType.CARD_SUSPENDED: {
              acc[type] = acc[type] || {
                ids: [],
                type,
                params: {
                  cardIds: [],
                },
              };

              const warning = acc[type];

              warning.ids.push(id);
              warning.params.cardIds = unionWith(
                warning.params.cardIds.concat(params.cardIds)
              );

              return acc;
            }

            default: {
              return acc;
            }
          }
        },
        {}
      )
    );
  };

  return getter;
};

export default warningsGroupByTypes;
