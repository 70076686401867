import { StatisticState } from "./types";

const state = (): StatisticState => ({
  declines: {
    loading: false,
    value: {
      declineRate: 0,
      declineAmount: 0,
      declineRateByCount: 0,
    },
  },
});

export default state;
