import { MutationTree } from "vuex";
import { FaqState } from "../types";
import setFaqList from "./setFaqList";
import setFaqLoading from "./setFaqLoading";

const mutations: MutationTree<FaqState> = {
  setFaqList,
  setFaqLoading,
};

export default mutations;
