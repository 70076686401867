import { Currency } from "./currency";

export enum CardPaymentSystem {
  VISA = "VISA",
  MASTERCARD = "MASTERCARD",
}

export enum CardStatus {
  ACTIVE = "ACTIVE",
  CLOSED = "CLOSED",
  FREEZE = "FREEZE",
  SUSPEND = "SUSPEND",
}

export enum CardLimitType {
  NO_LIMIT = "NO_LIMIT",
  TRANSACTION = "TRANSACTION",
  MONTHLY = "MONTHLY",
  YEAR = "YEAR",
  TOTAL = "YEAR",
}

export enum CardType {
  PREPAID = "PREPAID",
  POSTPAID = "POSTPAID",
}

export interface CardFee {
  depositFee: number;
  transactionFee: number;
  fixedFee: number;
  fixedFeeThreshold: number;
}

export interface CardOwner {
  firstName: string;
  lastName: string;
  email: string;
}

export interface Card {
  id: number;
  cardNumber: string;
  cardGroupName?: string;
  cardExpiryMonth: number;
  cardExpiryYear: number;
  issued_at: string;
  status: CardStatus;
  currency: Currency;
  cardCvv?: string;
  limitType: CardLimitType;
  spendAmount: number;
  refundAmount: number;
  limitAmount: number;
  balanceAmount: number;
  dailySpendAmount: number;
  dailyLimitAmount: number;
  autoRefillAmount?: number | null;
  autoRefillThreshold?: number | null;
  autoRefillEnabled: boolean;
  accessBatchCardActions?: boolean;
  bankId: number;
  note?: string;
  owner: CardOwner;
  prepaid: boolean;
  cardGroup?: CardGroup;
  overdraftAmount: number;
}

export interface CardBatchIssue {
  id: number;
  createdAt: string;
  cardGroup?: CardGroup;
  groupName?: string;
  numCardsTotal: number;
  numCardsIssued: number;
  closedAt?: string;
  compeleted: boolean;
  canceled: boolean;
  canceledAt: string;
  note?: string;
  cardType: CardType;
}

export interface CardGroup<I = number | null> {
  id: I;
  name: string;
  owner: string;
  style?: Record<string, string>;
}

export enum CardBatchUpdateAction {
  CLOSE = "CLOSE",
  FREEZE = "FREEZE",
  UNSUSPEND = "UNSUSPEND",
  UNFREEZE = "UNFREEZE",
}

export interface CardBatchUpdate {
  id: string;
  completed: boolean;
  cardIds: number[];
  cardAction: CardBatchUpdateAction;
  updatedCardIds: number[];
  cardUpdateErrors: Record<string, string>;
  canceledAt?: string;
  closedAt?: string;
}
