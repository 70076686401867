import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class MobileScreen extends Vue {
  @Prop({ type: Boolean, default: false }) private lightHeader!: boolean;
  @Prop({ type: String }) private readonly title?: string;
  @Prop({ type: String }) private readonly icon?: string;
  @Prop({ type: Boolean, default: false }) private readonly loading!: boolean;
  @Prop({ type: Boolean, default: true }) private readonly dense!: boolean;
}
