import { Getter } from "vuex";
import { RootState } from "@store/types";
import { ProfileGetters, ProfileState } from "../types";
import { hasRole } from "@/lib/role";

const userHasRole: Getter<ProfileState, RootState> = (
  state,
  getters: ProfileGetters
) => {
  const getter: ProfileGetters["userHasRole"] = (roles) => {
    return hasRole(getters.profileAuthority, roles);
  };

  return getter;
};

export default userHasRole;
