import Vue from "vue";
import Vuex from "vuex";
import { RootState } from "./types";
import modules from "./modules";
import state from "./state";
import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

Vue.use(Vuex);

const store = new Vuex.Store<RootState>({
  state,
  modules,
  actions,
  mutations,
  getters,
});

export default store;
