const userIdKey = "userId";
const userEmailKey = "userEmail";
const userTokenKey = "userToken";
const userTokenExpiresKey = "userTokenExpires";
const userGhostKey = "userGhost";

export function setUserInfo({
  token,
  expires,
  isGhost = false,
}: {
  token: string;
  expires: number;
  isGhost?: boolean;
}): void {
  localStorage.setItem(userTokenKey, token);
  localStorage.setItem(
    userTokenExpiresKey,
    `${Math.floor(expires * 1e3 + Date.now())}`
  );

  if (isGhost) {
    localStorage.setItem(userGhostKey, "1");
  }
}

export function getUserInfo(): {
  token: string;
  expired: boolean;
  expires: number;
  isGhost: boolean;
} {
  const userTokenExpires = +(localStorage.getItem(userTokenExpiresKey) || 0);
  const userToken = localStorage.getItem(userTokenKey);
  const userGhost = localStorage.getItem(userGhostKey);

  return {
    token: userToken || "",
    expired: Date.now() >= userTokenExpires,
    expires: userTokenExpires ? +userTokenExpires : 0,
    isGhost: !!userGhost,
  };
}

export function setUserProfileInfo({
  id,
  email,
}: {
  id?: number;
  email?: string;
}): void {
  if (email) {
    localStorage.setItem(userEmailKey, email);
  }

  if (id) {
    localStorage.setItem(userIdKey, id.toString());
  }
}

export function getUserProfileInfo(): {
  email: string;
  id: number;
} {
  const email = localStorage.getItem(userEmailKey) || "";
  const id = +(localStorage.getItem(userIdKey) || "");

  return {
    email,
    id,
  };
}

export function removeUserInfo(): void {
  localStorage.removeItem(userTokenKey);
  localStorage.removeItem(userTokenExpiresKey);
  localStorage.removeItem(userGhostKey);
  localStorage.removeItem(userEmailKey);
  localStorage.removeItem(userIdKey);
}
