import axios from "axios";
import { Action } from "vuex";
import { RootState } from "@store/types";
import { Wallet } from "@/types/wallet";
import { WalletState } from "../types";

const fetchWallets: Action<WalletState, RootState> = async ({
  commit,
  state: {
    wallets: { loading },
  },
}) => {
  if (loading) return;

  commit("setWalletsLoading", true);

  try {
    const { data: walletsList } = await axios.get<Wallet[]>("/wallets/list");

    commit("setWallets", walletsList);
    commit("setWalletsLoading", false);
    commit("setWalletsLoaded", true);
  } finally {
    commit("setWalletsLoading", false);
  }
};

export default fetchWallets;
