import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({
  inheritAttrs: false,
})
export default class Menu extends Vue {
  @Prop({ type: Boolean, default: false }) private value!: boolean;
  @Prop({ type: Boolean, default: true })
  private readonly renderContentAfterShow!: boolean;
  @Prop({ type: String, default: "" })
  private readonly contentClass!: string;
  @Prop({ type: Boolean, default: true })
  private readonly hideOverlay!: boolean;
  @Prop({ type: Number, default: 5 }) private readonly overlayZIndex!: number;
  @Prop({ type: String, default: "slide-x-transition" })
  private readonly transition!: string;

  private showedContent = false;
  private showedMenu = false;

  private get localContentClass() {
    return ["menu", this.contentClass].join(" ");
  }

  private get localListeners() {
    const listeners = { ...this.$listeners };

    delete listeners.input;

    return listeners;
  }

  private toggleMenu(showed = !this.showedMenu) {
    this.showedMenu = showed;
  }

  private closeMenu() {
    this.toggleMenu(false);
  }

  private mounted() {
    let transitionTimeoutId = 0;
    let requestAnimationFrameId = 0;

    this.showedMenu = this.value;
    this.showedContent = this.showedMenu;

    this.$watch(
      () => {
        return this.value;
      },
      (value) => {
        this.showedMenu = value;
      }
    );

    this.$watch(
      () => {
        return this.showedMenu;
      },
      (showedMenu) => {
        window.clearTimeout(transitionTimeoutId);
        window.cancelAnimationFrame(requestAnimationFrameId);
        this.showedContent = true;

        this.$emit("update:showed-menu", showedMenu);

        if (!showedMenu) {
          transitionTimeoutId = window.setTimeout(() => {
            requestAnimationFrameId = window.requestAnimationFrame(() => {
              this.showedContent = !this.renderContentAfterShow;

              this.$emit("input", false);
            });
          }, 300);
        } else {
          this.$emit("input", true);
        }
      }
    );

    this.$once("hook:beforeDestroy", () => {
      window.clearTimeout(transitionTimeoutId);
      window.cancelAnimationFrame(requestAnimationFrameId);
    });
  }
}
