import { Action } from "vuex";
import { RootState } from "@store/types";
import { ProfileState } from "../types";
import axios from "axios";

const disableSecondFactor: Action<ProfileState, RootState> = async (
  _,
  { code }
) => {
  return axios.post("/profile/secondFactor/disable", {
    code,
  });
};

export default disableSecondFactor;
