import { Action } from "vuex";
import axios from "axios";
import { RootState } from "@store/types";
import { CashflowState } from "../types";
import { CashFlow } from "@/types/cashFlow";

const filterCashflow: Action<CashflowState, RootState> = async (
  _,
  { cancelToken, ...payload }
) => {
  const { data } = await axios.post<CashFlow[]>("/cashflow/filter", payload, {
    cancelToken,
  });

  return data;
};

export default filterCashflow;
