import { MutationTree } from "vuex";
import { WarningState } from "../types";

import setFeedWarningList from "./setFeedWarningList";
import setFeedWarningLoading from "./setFeedWarningLoading";

const mutations: MutationTree<WarningState> = {
  setFeedWarningList,
  setFeedWarningLoading,
};

export default mutations;
