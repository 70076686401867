import { Mutation } from "vuex";
import { FaqState } from "../types";
import { LangCode } from "@/types/lang";

const setFaqLoading: Mutation<FaqState> = (
  state,
  { langCode, value }: { langCode: LangCode; value: boolean }
) => {
  state.loading[langCode] = value;
};

export default setFaqLoading;
