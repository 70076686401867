import { Action } from "vuex";
import axios from "axios";
import { RootState } from "@store/types";
import { BillingState } from "../types";
import {
  BillingPaymentMethod,
  PaymentMethodId,
  UsdtTransferPaymentMethod,
} from "@/types/billing";
import { Currency } from "@/types/currency";

const ebabledPaymentMethods = [
  PaymentMethodId.CAPITALIST_USD,
  PaymentMethodId.CAPITALIST_USDT_TRC20,
  PaymentMethodId.USDT_TRANSFER,
];

const fetchBillingPaymentMethods: Action<BillingState, RootState> = async ({
  dispatch,
}) => {
  const { data } = await axios.get<BillingPaymentMethod[]>(
    "/billing/paymentMethods"
  );

  if (ebabledPaymentMethods.includes(PaymentMethodId.USDT_TRANSFER_HANDLE)) {
    data.unshift({
      id: PaymentMethodId.USDT_TRANSFER_HANDLE,
      name: "USDT transfer",
      comission: 0.03,
      minAmount: 200,
      maxAmount: 5000,
      availableCurrency: [Currency.USD],
      externalFields: {
        ERC: "0xb86c346DBc682DD26FDb53f4fa97b2524f1E4927",
        TRC: "TXeEGSW6X5jhskg5QGLHqLYRwT4tatCSbb",
      },
    });
  }

  const usdtTransferItem = (
    ebabledPaymentMethods.includes(PaymentMethodId.USDT_TRANSFER)
      ? data.find(({ id }) => id === PaymentMethodId.USDT_TRANSFER)
      : undefined
  ) as UsdtTransferPaymentMethod | undefined;

  if (usdtTransferItem) {
    const { address: TRC } = await dispatch(
      "blockchain/fetchTronWallet",
      null,
      {
        root: true,
      }
    );

    const { rate: usdtComission } = await dispatch(
      "blockchain/fetchBlockchainCommission",
      { amount: 1 },
      {
        root: true,
      }
    );

    usdtTransferItem.comission = usdtComission;
    usdtTransferItem.minAmount = 200;
    usdtTransferItem.fixedComission = 2;
    usdtTransferItem.currency =
      usdtTransferItem.availableCurrency[0] || Currency.USD;
    usdtTransferItem.externalFields = {
      TRC,
    };
  }

  return data.filter(({ id }) => ebabledPaymentMethods.includes(id));
};

export default fetchBillingPaymentMethods;
