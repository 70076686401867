import { Getter } from "vuex";
import { getRandomInt, checkAvatar } from "@helpers";
import { RootState } from "@store/types";
import { ProfileState } from "../types";

const profileAvatar: Getter<ProfileState, RootState> = (state) => {
  const avatar = state.profile?.avatar || "";

  return checkAvatar(avatar) ? avatar : `smp-${getRandomInt(11, 30)}`;
};

export default profileAvatar;
