import { CardState } from "./types";

const state = (): CardState => ({
  cards: {
    list: [],
    loading: false,
    loaded: false,
  },
  groups: [],
});

export default state;
