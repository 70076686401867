import { Action } from "vuex";
import { RootState } from "@store/types";
import { AuthState } from "../types";
import axios from "axios";

const logIn: Action<AuthState, RootState> = async (
  { commit },
  { email, password, secondFactorCode }
) => {
  const {
    data: { token, expires },
  } = await axios.post(
    "/auth/login",
    {
      email,
      password,
      secondFactorCode,
    },
    {
      isAxiosRetry: false,
    }
  );

  commit("setUserInfo", { token, expires });
};

export default logIn;
