import Vue from "vue";
import type VueRouter from "vue-router";
import { ENABLED_SENTRY } from "@config/base";

export default async function initSentry({
  router,
}: {
  router: VueRouter;
}): Promise<typeof Sentry | undefined> {
  if (!ENABLED_SENTRY) return;

  const Sentry = await import("@sentry/vue");
  const { BrowserTracing } = await import("@sentry/tracing");

  Sentry.init({
    Vue,
    dsn: "https://2a2c4481ca7d465b9d2ebfa644f96340@sentry2.dats.tech/30",
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost", "multicards.io", /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });

  return Sentry;
}
