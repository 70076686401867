import { Action } from "vuex";
import axios from "axios";
import { RootState } from "@store/types";
import { CardGroupState, CardGroupGetters } from "../types";
import { CardGroup } from "@/types/card";
import { uniqCid } from "@helpers";

let createCardGroupRequestQueue: number[] = [];

const createCardGroup: Action<CardGroupState, RootState> = async (
  { commit, dispatch, getters },
  { name }
) => {
  const { cardGroups } = getters as CardGroupGetters;

  const searchedGroup = cardGroups.find(
    (group) => group.name.toLowerCase() === name.toLowerCase()
  );

  if (searchedGroup) {
    return searchedGroup;
  }

  const requestId = uniqCid();

  createCardGroupRequestQueue.push(requestId);

  commit("setCardGroupsLoading", true);

  try {
    const { data } = await axios.post<CardGroup>("/card/group/create", {
      name,
    });

    createCardGroupRequestQueue = createCardGroupRequestQueue.filter(
      (id) => id !== requestId
    );

    if (!createCardGroupRequestQueue.length) {
      commit("setCardGroupsLoading", false);
      await dispatch("fetchCardGroups");
    }

    return data;
  } catch {
    createCardGroupRequestQueue = createCardGroupRequestQueue.filter(
      (id) => id !== requestId
    );
  }
};

export default createCardGroup;
