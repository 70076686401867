import { RootState } from "@store/types";
import { GetterTree } from "vuex";
import { PartnerState } from "../types";
import partnersList from "./partnersList";
import partnersLoading from "./partnersLoading";

const getters: GetterTree<PartnerState, RootState> = {
  partnersList,
  partnersLoading,
};

export default getters;
