import { RootState } from "@store/types";
import { GetterTree } from "vuex";
import { CardGroupState } from "../types";
import cardGroups from "./cardGroups";
import cardGroupsCurrentUser from "./cardGroupsCurrentUser";
import loadingCardGroups from "./loadingCardGroups";

const getters: GetterTree<CardGroupState, RootState> = {
  cardGroups,
  cardGroupsCurrentUser,
  loadingCardGroups,
};

export default getters;
