import { Action } from "vuex";
import API from "@api";
import { RootState } from "@store/types";
import { CardState } from "../types";
import { Card, CardType } from "@/types/card";
import { ProfileGetters } from "@store/modules/profile/types";
import { accessibleBatchCardActions } from "@/lib/card";
import { getFallbackCardGroup } from "@/lib/creaditCard";

let fetchCardsPromise: Promise<Card[]> | null = null;

const fetchCards: Action<CardState, RootState> = async (
  { commit, state, rootGetters },
  payload = {}
) => {
  if (fetchCardsPromise) {
    return fetchCardsPromise;
  }

  const { forceUpdate = false } = payload;

  if (!forceUpdate && state.cards.loaded) {
    return;
  }

  commit("setCardsLoading", true);

  try {
    fetchCardsPromise = API.card.fetchCards();

    const cards = await fetchCardsPromise;

    const cardGroups: Record<
      number,
      {
        value: number | null;
        text: string;
        cardTypes: CardType[];
        color?: string;
        textColor?: string;
      }
    > = {};

    const profileEmail = rootGetters[
      "profile/profileEmail"
    ] as ProfileGetters["profileEmail"];

    cards.forEach((card) => {
      const fallbackCardGroup = getFallbackCardGroup({
        ownerEmail: profileEmail,
      });

      const cardGroupId = card.cardGroup?.id ?? fallbackCardGroup.id;
      const cardGroupName = card.cardGroup?.name ?? fallbackCardGroup.name;
      const cardGroupOwner = card.cardGroup?.owner ?? fallbackCardGroup.owner;
      const cardGroupStyle =
        cardGroupId === fallbackCardGroup.id
          ? fallbackCardGroup.style
          : undefined;

      const cardGroupKey = cardGroupId || 0;

      let group = cardGroups[cardGroupKey];

      if (!group) {
        group = Object.freeze({
          value: cardGroupId,
          text: cardGroupName,
          cardTypes: [],
          owner: cardGroupOwner,
          style: cardGroupStyle,
        });

        cardGroups[cardGroupKey] = group;
      }

      if (card.prepaid && !group.cardTypes.includes(CardType.PREPAID)) {
        group.cardTypes.push(CardType.PREPAID);
      } else if (
        !card.prepaid &&
        !group.cardTypes.includes(CardType.POSTPAID)
      ) {
        group.cardTypes.push(CardType.POSTPAID);
      }

      if (!card.cardGroup) {
        card.cardGroup = {
          id: cardGroupId,
          name: cardGroupName,
          owner: cardGroupOwner,
          style: cardGroupStyle,
        };

        card.cardGroupName = cardGroupName;
      }

      card.accessBatchCardActions = accessibleBatchCardActions({
        status: card.status,
      });
    });

    commit("setCardGroups", Object.values(cardGroups));
    commit("setCardsLoaded", true);
    commit("setCards", cards);
  } finally {
    commit("setCardsLoading", false);
    fetchCardsPromise = null;
  }
};

export default fetchCards;
