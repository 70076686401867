import { VuetifyIconComponent } from "vuetify/types/services/icons";

import suitcase from "./suitcase.vue";
import tablerClock from "./tablerClock.vue";
import ticketOne from "./ticket-one.vue";
import nestClock from "./nest-clock.vue";
import undo from "./undo.vue";
import star from "./star.vue";
import money from "./money.vue";
import wallet from "./wallet.vue";
import autoRefill from "./auto-refill.vue";
import accountCircle from "./account-circle.vue";
import play from "./play.vue";
import pause from "./pause.vue";
import pauseL from "./pause-l.vue";
import closeB from "./close-b.vue";
import question from "./question.vue";
import questionS from "./question-s.vue";
import questionC from "./question-c.vue";
import exit from "./exit.vue";
import facebook from "./facebook.vue";
import hourglass from "./hourglass.vue";
import incomingFundsTransfer from "./incoming-funds-transfer.vue";
import intTransactionFee from "./int-transaction-fee.vue";
import layerGroup from "./layer-group.vue";
import checkmark from "./checkmark.vue";
import repeatCircle from "./repeat-circle.vue";
import lockClock from "./lock-clock.vue";
import lock from "./lock.vue";
import pieChartFilled from "./pie-chart-filled.vue";
import pieChart from "./pie-chart.vue";
import eur from "./eur.vue";
import rub from "./rub.vue";
import dollar from "./dollar.vue";
import reload from "./reload.vue";
import filter from "./filter.vue";
import fire from "./fire.vue";
import fundsDeposit from "./funds-deposit.vue";
import fundsExchange from "./funds-exchange.vue";
import fundsTransfer from "./funds-transfer.vue";
import settings from "./settings.vue";
import severeCold from "./severe-cold.vue";
import moveMenu from "./move-menu.vue";
import user from "./user.vue";
import email from "./email.vue";
import edit from "./edit.vue";
import phone from "./phone.vue";
import telegram from "./telegram.vue";
import telegramC from "./telegram-c.vue";
import crown from "./crown.vue";
import book from "./book.vue";
import bell from "./bell.vue";
import power from "./power.vue";
import link from "./link.vue";
import plus from "./plus.vue";
import tg from "./tg.vue";
import menu from "./menu.vue";
import photoCamera from "./photo-camera.vue";
import userImages from "./user-images.vue";
import arrowR from "./arrow-r.vue";
import leadership from "./leadership.vue";
import creditCard from "./credit-card.vue";
import transactions from "./transactions.vue";
import dotsVertical from "./dots-vertical.vue";
import close from "./close.vue";
import trash from "./trash.vue";
import check from "./check.vue";
import copy from "./copy.vue";
import mail from "./mail.vue";
import megaphoneLine from "./megaphone-line.vue";
import home from "./home.vue";
import calendar from "./calendar.vue";
import cardCloseRefund from "./card-close-refund.vue";
import cardDeposit from "./card-deposit.vue";
import cardIssueFee from "./card-issue-fee.vue";
import cardWithdraw from "./card-withdraw.vue";
import danger from "./danger.vue";
import declineFee from "./decline-fee.vue";
import arrowDropDown from "./arrow-drop-down.vue";
import userBusiness from "./user-business.vue";
import teamLine from "./team-line.vue";
import moneyStack from "./money-stack.vue";
import creditCardB from "./credit-card-b.vue";
import checkAll from "./check-all.vue";
import userGroup from "./user-group.vue";
import refresh from "./refresh.vue";
import archiveIn from "./archive-in.vue";
import archiveOut from "./archive-out.vue";
import arrowsExchange from "./arrows-exchange.vue";
import magnifyingGlassB from "./magnifying-glass-b.vue";
import magnifyingGlass from "./magnifying-glass.vue";
import noConnection from "./no-connection.vue";
import newspaper from "./newspaper.vue";
import toolsWrench from "./tools-wrench.vue";
import transactionFee from "./transaction-fee.vue";
import transactionRefundToBalance from "./transaction-refund-to-balance.vue";
import transactionRefund from "./transaction-refund.vue";
import alert from "./alert.vue";

const icons = {
  suitcase,
  tablerClock,
  ticketOne,
  nestClock,
  star,
  undo,
  money,
  wallet,
  autoRefill,
  accountCircle,
  pause,
  pauseL,
  play,
  closeB,
  question,
  exit,
  facebook,
  questionC,
  questionS,
  hourglass,
  incomingFundsTransfer,
  intTransactionFee,
  layerGroup,
  checkmark,
  repeatCircle,
  lockClock,
  lock,
  eur,
  rub,
  dollar,
  tg,
  home,
  danger,
  declineFee,
  copy,
  mail,
  megaphoneLine,
  filter,
  fire,
  fundsDeposit,
  fundsExchange,
  fundsTransfer,
  reload,
  settings,
  severeCold,
  moveMenu,
  user,
  email,
  edit,
  phone,
  telegram,
  telegramC,
  crown,
  book,
  bell,
  power,
  link,
  plus,
  menu,
  arrowDropDown,
  userBusiness,
  photoCamera,
  userImages,
  arrowR,
  leadership,
  creditCard,
  transactions,
  dotsVertical,
  close,
  trash,
  check,
  teamLine,
  moneyStack,
  checkAll,
  userGroup,
  refresh,
  magnifyingGlassB,
  magnifyingGlass,
  arrowsExchange,
  archiveIn,
  archiveOut,
  creditCardB,
  pieChart,
  pieChartFilled,
  calendar,
  cardCloseRefund,
  cardDeposit,
  cardIssueFee,
  cardWithdraw,
  noConnection,
  newspaper,
  toolsWrench,
  transactionFee,
  transactionRefundToBalance,
  transactionRefund,
  alert,
};

export default Object.entries(icons).reduce<
  Record<string, VuetifyIconComponent>
>((acc, [name, component]) => {
  acc[name] = {
    component,
  };
  return acc;
}, {});
