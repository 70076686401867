import { RootState } from "@store/types";
import { Getter } from "vuex";
import { WalletState, WalletGetters } from "../types";

const hasWalletCurrency: Getter<WalletState, RootState> = (
  _,
  getters: WalletGetters
) => {
  const hasWalletCurrencyGetter: WalletGetters["hasWalletCurrency"] = (
    currency
  ) => {
    return getters.walletsCurrencies.includes(currency);
  };

  return hasWalletCurrencyGetter;
};

export default hasWalletCurrency;
