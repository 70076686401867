import axios from "axios";
import { Action } from "vuex";
import { RootState } from "@store/types";
import { UserState } from "../types";
import { UserWebPreferencesKey, UserWebPreferencesValue } from "@/types/user";

const fetchUserWebPreferences: Action<UserState, RootState> = async (
  { commit, state },
  { key }: { key: UserWebPreferencesKey }
) => {
  if (state.webpreferences[key].loading || state.webpreferences[key].loaded)
    return;

  commit("setUserWebPreferences", { key, loading: true });

  try {
    const { data } = await axios.get<UserWebPreferencesValue>(
      "/user/webpreferences",
      {
        params: {
          param: key,
        },
      }
    );

    commit("setUserWebPreferences", {
      key,
      value: data,
      loading: false,
      loaded: true,
    });
  } catch {
    commit("setUserWebPreferences", { key, loading: false });
  }
};

export default fetchUserWebPreferences;
