import axios from "axios";
import { Action } from "vuex";
import { RootState } from "@store/types";
import { BankState } from "../types";

const fetchTeamBanks: Action<BankState, RootState> = async ({
  dispatch,
  commit,
  state,
}) => {
  if (state.teamBanks.loading) return;

  commit("setTeamBanksLoading", true);

  try {
    const [{ data }] = await Promise.all([
      axios.get<number[]>("/team/bank/list"),
      dispatch("fetchAllBanks"),
    ]);

    commit("setTeamBanks", data);
    commit("setTeamBanksLoading", false);
  } catch {
    commit("setTeamBanksLoading", false);
  }
};

export default fetchTeamBanks;
