import { ActionTree } from "vuex";
import { WalletState } from "../types";
import { RootState } from "@store/types";
import fetchWallets from "./fetchWallets";
import fetchExchangeRates from "./fetchExchangeRates";
import transferFundsWallet from "./transferFundsWallet";
import exchangeFundsWallet from "./exchangeFundsWallet";

const actions: ActionTree<WalletState, RootState> = {
  fetchWallets,
  fetchExchangeRates,
  transferFundsWallet,
  exchangeFundsWallet,
};

export default actions;
