import { MutationTree } from "vuex";
import { StatisticState } from "../types";
import setStatisticDeclines from "./setStatisticDeclines";
import setStatisticDeclinesLoading from "./setStatisticDeclinesLoading";

const mutations: MutationTree<StatisticState> = {
  setStatisticDeclines,
  setStatisticDeclinesLoading,
};

export default mutations;
