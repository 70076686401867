import { Action } from "vuex";
import API from "@api";
import { nestedFreeze } from "@helpers";
import { RootState } from "@store/types";
import { ProfileState } from "../types";
import { Promocode } from "@/types/promocode";

let fetchAvailablePromocodesPromise: Promise<Promocode[]> | null = null;

const fetchAvailablePromocodes: Action<ProfileState, RootState> = async ({
  commit,
}) => {
  if (fetchAvailablePromocodesPromise) {
    return fetchAvailablePromocodesPromise;
  }

  commit("setPromocodesLoading", true);

  try {
    fetchAvailablePromocodesPromise = API.profile.fetchProfilePromocodes();

    const promocodes = await fetchAvailablePromocodesPromise;

    commit("setPromocodes", nestedFreeze(promocodes));
  } finally {
    commit("setPromocodesLoading", false);
    fetchAvailablePromocodesPromise = null;
  }
};

export default fetchAvailablePromocodes;
