import { ActionTree } from "vuex";
import { CashflowState } from "../types";
import { RootState } from "@store/types";
import filterCashflow from "./filterCashflow";

const actions: ActionTree<CashflowState, RootState> = {
  filterCashflow,
};

export default actions;
