import { MutationTree } from "vuex";
import { PartnerState } from "../types";
import setPartnersList from "./setPartnersList";
import setPartnersLoading from "./setPartnersLoading";

const mutations: MutationTree<PartnerState> = {
  setPartnersList,
  setPartnersLoading,
};

export default mutations;
