import { MutationTree } from "vuex";
import { CardGroupState } from "../types";
import setCardGroups from "./setCardGroups";
import setCardGroupsLoading from "./setCardGroupsLoading";

const mutations: MutationTree<CardGroupState> = {
  setCardGroups,
  setCardGroupsLoading,
};

export default mutations;
