import { ActionTree } from "vuex";
import { TeamState } from "../types";
import { RootState } from "@store/types";
import fetchTeamHierarchy from "./fetchTeamHierarchy";
import fetchTeamMembers from "./fetchTeamMembers";
import fetchTeamInvite from "./fetchTeamInvite";
import updateTeamMember from "./updateTeamMember";
import refreshTeamInvite from "./refreshTeamInvite";

const actions: ActionTree<TeamState, RootState> = {
  fetchTeamHierarchy,
  fetchTeamMembers,
  fetchTeamInvite,
  updateTeamMember,
  refreshTeamInvite,
};

export default actions;
