import { BankState } from "./types";

export default (): BankState => ({
  allBanks: {
    loading: false,
    list: [],
  },
  userBanks: {
    loading: false,
    list: [],
  },
  teamBanks: {
    loading: false,
    list: [],
  },
});
