import { RootState } from "@store/types";
import { Getter } from "vuex";
import { UserGetters, UserState } from "../types";

const userWebPreferences: Getter<UserState, RootState> = (state) => {
  const getter: UserGetters["userWebPreferences"] = ({ key }) => {
    return state.webpreferences[key];
  };

  return getter;
};

export default userWebPreferences;
