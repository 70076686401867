import { RootState } from "@store/types";
import { GetterTree } from "vuex";
import { StatisticState } from "../types";
import statisticDeclines from "./statisticDeclines";
import statisticDeclinesLoading from "./statisticDeclinesLoading";

const getters: GetterTree<StatisticState, RootState> = {
  statisticDeclines,
  statisticDeclinesLoading,
};

export default getters;
