import { ModuleTree } from "vuex";
import { RootState } from "@store/types";

import group from "./group";

const modules: ModuleTree<RootState> = {
  group,
};

export default modules;
