import { RootState } from "@store/types";
import { Getter } from "vuex";
import { ProfileState } from "../types";

const canViewFacebookCodeNotifications: Getter<ProfileState, RootState> = (
  state
) => {
  return state.profile?.canViewFacebookCodeNotifications ?? false;
};

export default canViewFacebookCodeNotifications;
