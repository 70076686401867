import { CardStatus } from "@/types/card";

// Поставить на паузу карту?
export function canFreezeCard({ status }: { status: CardStatus }): boolean {
  return [CardStatus.ACTIVE, CardStatus.SUSPEND].includes(status);
}

// Снять с паузы карту?
export function canUnfreezeCard({ status }: { status: CardStatus }): boolean {
  return [CardStatus.FREEZE].includes(status);
}

// Разморозить карту?
export function canUnsuspendCard({ status }: { status: CardStatus }): boolean {
  return [CardStatus.SUSPEND].includes(status);
}

// Закрыть карту?
export function canCloseCard({ status }: { status: CardStatus }): boolean {
  return [CardStatus.ACTIVE, CardStatus.FREEZE, CardStatus.SUSPEND].includes(
    status
  );
}

export function accessibleBatchCardActions({
  status,
}: {
  status: CardStatus;
}): boolean {
  return [CardStatus.ACTIVE, CardStatus.SUSPEND, CardStatus.FREEZE].includes(
    status
  );
}
