import { Getter } from "vuex";
import { RootState } from "@store/types";
import { TeamState, TeamGetters } from "../types";
import { hasRole } from "@/lib/role";
import { Role } from "@/types/role";

const mediabuyerMembers: Getter<TeamState, RootState> = (
  _,
  getters: TeamGetters
) => {
  return getters.members.filter(({ authority }) =>
    hasRole(authority, Role.ROLE_MEDIABUYER)
  );
};

export default mediabuyerMembers;
