import { RootState } from "@store/types";
import { Getter } from "vuex";
import { WalletState, WalletGetters } from "../types";

const wallet: Getter<WalletState, RootState> = (
  _,
  { walletsList }: WalletGetters
) => {
  const getter: WalletGetters["wallet"] = (currency) => {
    return (
      walletsList.find(
        ({ currency: walletCurrency }) => currency === walletCurrency
      ) || {
        currency,
        balance: 0,
        cardBalance: 0,
      }
    );
  };

  return getter;
};

export default wallet;
