import { Action } from "vuex";
import API from "@api";
import { RootState } from "@store/types";
import { ProfileState } from "../types";

const updateProfile: Action<ProfileState, RootState> = async (
  context,
  payload
) => {
  await API.profile.updateProfile(payload);

  await context.dispatch("fetchProfile");
};

export default updateProfile;
