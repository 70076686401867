import { ActionTree } from "vuex";
import { BankState } from "../types";
import { RootState } from "@store/types";
import fetchAllBanks from "./fetchAllBanks";
import fetchUserBanks from "./fetchUserBanks";
import fetchTeamBanks from "./fetchTeamBanks";

const actions: ActionTree<BankState, RootState> = {
  fetchAllBanks,
  fetchUserBanks,
  fetchTeamBanks,
};

export default actions;
