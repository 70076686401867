import { ActionTree } from "vuex";
import { UserState } from "../types";
import { RootState } from "@store/types";
import fetchUserWebPreferences from "./fetchUserWebPreferences";
import updateUserWebPreferences from "./updateUserWebPreferences";

const actions: ActionTree<UserState, RootState> = {
  fetchUserWebPreferences,
  updateUserWebPreferences,
};

export default actions;
