import { RootState } from "@store/types";
import { Getter } from "vuex";
import { ProfileState } from "../types";

const postpaidCardIssueRemainingLimit: Getter<ProfileState, RootState> = (
  state
) => {
  const postpaidCardIssueRemainingLimit =
    state.profile?.postpaidCardIssueRemainingLimit || 0;

  return Math.max(postpaidCardIssueRemainingLimit, 0);
};

export default postpaidCardIssueRemainingLimit;
