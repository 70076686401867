import { ProfileState } from "./types";

const state = (): ProfileState => ({
  status: null,
  profile: null,
  promocodes: {
    list: [],
    loading: false,
  },
});

export default state;
