import axios, { AxiosError } from "axios";
import { checkOnline } from "@helpers";
import store from "@/store";

const isRetryableError = (error: AxiosError): boolean => {
  return (
    !axios.isCancel(error) &&
    error.code !== "ECONNABORTED" &&
    !error.response?.status
  );
};

axios.interceptors.request.use(
  (config) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    config._retryCount = config._retryCount || 0;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(undefined, async (error: AxiosError) => {
  const { config } = error;
  const method = config?.method?.toLowerCase();

  const isAxiosRetry = config?.isAxiosRetry ?? method === "get";

  if (
    isAxiosRetry &&
    method &&
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    config?._retryCount <= 3 &&
    isRetryableError(error)
  ) {
    return new Promise((resolve, reject) => {
      let checkOnlinePromise: Promise<boolean> | null = null;

      const intervalId = window.setInterval(() => {
        checkOnlinePromise = checkOnlinePromise || checkOnline();

        checkOnlinePromise.then((onLine) => {
          if (onLine) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            config._retryCount++;
            axios(config).then(resolve).catch(reject);
            window.clearInterval(intervalId);
          }

          checkOnlinePromise = null;
          store.commit("setOnLine", onLine);
        });
      }, 3e3);
    });
  }

  return Promise.reject(error);
});
