import { RootState } from "./types";

const state = (): RootState => {
  const data: Omit<RootState, "profile" | "auth"> = {
    utmLabels: {},
    onLine: true,
    isReady: false,
    errorStatus: null,
    enabledHalloweenDesign:
      new Date(2023, 9, 27, 0, 0).getTime() <= Date.now() &&
      Date.now() <= new Date(2023, 10, 3, 23, 59).getTime(),
    enabledNewYearDesign:
      new Date(2023, 11, 22).getTime() <= Date.now() &&
      Date.now() <= new Date(2024, 0, 15).getTime(),
  };

  return data as RootState;
};

export default state;
