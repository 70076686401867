import { Mutation } from "vuex";
import { StatisticState } from "../types";

const setStatisticDeclinesLoading: Mutation<StatisticState> = (
  state,
  payload
) => {
  state.declines.loading = payload;
};

export default setStatisticDeclinesLoading;
