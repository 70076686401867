import axios from "axios";
import { Action } from "vuex";
import { RootState } from "@store/types";
import { BlockchainState } from "../types";

const fetchBlockchainCommission: Action<BlockchainState, RootState> = async (
  _,
  { amount }
) => {
  const { data } = await axios.get("/blockchain/commission", {
    params: {
      amount,
    },
  });

  return data;
};

export default fetchBlockchainCommission;
