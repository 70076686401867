import { Action } from "vuex";
import { RootState } from "@store/types";
import { AuthState } from "../types";
import axios from "axios";

const changePassword: Action<AuthState, RootState> = async (
  _,
  { newPassword, secret }
) => {
  const { data: checked } = await axios.post("/auth/password/change", {
    newPassword,
    secret,
  });

  return checked ? Promise.resolve(true) : Promise.reject(false);
};

export default changePassword;
