import { RootState } from "@store/types";
import { Getter } from "vuex";
import { WalletState, WalletGetters } from "../types";

const walletsCurrencies: Getter<WalletState, RootState> = (
  _,
  getters: WalletGetters
) => {
  return getters.walletsList.map(({ currency }) => currency);
};

export default walletsCurrencies;
