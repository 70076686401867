import { Mutation } from "vuex";
import { CardState } from "../types";

const setCardGroups: Mutation<CardState> = (
  state,
  payload: CardState["groups"]
) => {
  state.groups = payload.concat([]).sort((a, b) => {
    const aName = a.text.toLowerCase();
    const bName = b.text.toLowerCase();

    if (aName < bName) {
      return -1;
    }

    if (aName > bName) {
      return 1;
    }

    return 0;
  });
};

export default setCardGroups;
