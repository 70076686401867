import { ActionTree } from "vuex";
import { BillingState } from "../types";
import { RootState } from "@store/types";
import fetchBillingInvoices from "./fetchBillingInvoices";
import fetchBillingRefills from "./fetchBillingRefills";
import fetchBillingPaymentMethods from "./fetchBillingPaymentMethods";
import createBillingInvoice from "./createBillingInvoice";

const actions: ActionTree<BillingState, RootState> = {
  fetchBillingInvoices,
  fetchBillingRefills,
  fetchBillingPaymentMethods,
  createBillingInvoice,
};

export default actions;
