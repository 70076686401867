import { RootState } from "@store/types";
import { GetterTree } from "vuex";
import { TeamState } from "../types";

import members from "./members";
import membersLoading from "./membersLoading";
import teamHierarchy from "./teamHierarchy";
import teamHierarchyLoading from "./teamHierarchyLoading";
import enabledMembers from "./enabledMembers";
import mediabuyerMembers from "./mediabuyerMembers";
import teamleadMembers from "./teamleadMembers";
import totalTeamBalance from "./totalTeamBalance";

const getters: GetterTree<TeamState, RootState> = {
  members,
  membersLoading,
  teamHierarchy,
  teamHierarchyLoading,
  enabledMembers,
  mediabuyerMembers,
  teamleadMembers,
  totalTeamBalance,
};

export default getters;
